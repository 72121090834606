const STORE_CATEGORIES = process.env.REACT_APP_STORE_CATEGORIES.split(',');
const API_URL = process.env.REACT_APP_API_URL;

const BASE_DOMAIN = process.env.REACT_APP_BASE_DOMAIN;

const BUSINESSBAY_API = process.env.REACT_APP_BUSINESSBAY_API;

const AUTH0_CONFIG = {
  domain: process.env.REACT_APP_AUTH0_CONFIG_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CONFIG_CLIENT_ID,
  redirectUri: process.env.REACT_APP_AUTH0_CONFIG_REDIRECT_URI,
  audience: process.env.REACT_APP_AUTH0_CONFIG_AUDIENCE,
  responseType: 'token id_token',
  scope: 'openid profile email',
  language: process.env.REACT_APP_AUTH0_CONFIG_LANGUAGE,
};

const ANALYTICS_KEY = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;

const DEFAULT_ITEMS_BY_PAGE = process.env.REACT_APP_DEFAULT_ITEMS_BY_PAGE;

const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_CONFIG_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_CONFIG_APP_ID,
};

const COMPANY_ID = process.env.REACT_APP_COMPANY_ID;

module.exports = {
  API_URL,
  DEFAULT_ITEMS_BY_PAGE,
  STORE_CATEGORIES,
  AUTH0_CONFIG,
  BASE_DOMAIN,
  ANALYTICS_KEY,
  BUSINESSBAY_API,
  FIREBASE_CONFIG,
  COMPANY_ID,
};
