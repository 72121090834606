import React, { Component } from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Pagination from 'react-bootstrap/Pagination';
import ProductListItem from '../productListItem/ProductListItem';
import { selectPage, addItemToKart } from '../../redux/actions';
import Loader from '../loader/loader';
import styled from 'styled-components';

class ProductList extends Component {

    constructor(props) {
        super(props);
        this.props = props;
        
        this.state = {
            isLoading: false,
            products: this.props.products,
            modalShow: false,
            selectedCategory: this.props.category,
            currentPage: this.props.currentPage ? parseInt(this.props.currentPage) : 1,
            pages: this.props.pages,
            page: 1
        };
    }

    buildRow(product1, product2 = null, product3 = null) {
        return <Row key={product1.id}>
            <Col xs={12} md={12} lg={4} className="with-margin-bottom">
                <ProductListItem product={product1} key={product1.id} photos={product1.photos} model={product1.model} price={product1.price} price_with_discount={product1.price_with_discount} stock={product1.stock} description={product1.description} addToKartHandler={this.props.addItemToKart}/>
            </Col>
            <Col xs={12} md={12} lg={4} className="with-margin-bottom">
                { product2 !== null &&
                <ProductListItem product={product2} key={product2.id} photos={product2.photos} model={product2.model} price={product2.price} price_with_discount={product2.price_with_discount} stock={product2.stock} description={product2.description} addToKartHandler={this.props.addItemToKart}/>}
            </Col>
            <Col xs={12} md={12} lg={4} className="with-margin-bottom">
                { product3 !== null &&
                <ProductListItem product={product3} key={product3.id} photos={product3.photos} model={product3.model} price={product3.price} price_with_discount={product3.price_with_discount} stock={product3.stock} description={product3.description} addToKartHandler={this.props.addItemToKart}/>}
            </Col>
        </Row>
    }

    getRowsOfProducts(products){
        const numberOfRows = products.length/3;
        const numberOfCompleteRows = parseInt(numberOfRows);
        const listProducts = [];
        
        let i = 0, j = 0;
        while(i < numberOfCompleteRows){
            listProducts.push(this.buildRow(products[j], products[j+1], products[j+2]))
            j+=3;
            i++;
        }

        const remainingItems = products.length - numberOfCompleteRows * 3;

        if(numberOfRows - numberOfCompleteRows > 0) listProducts.push(this.buildRow(products[products.length-1], remainingItems === 2 ? products[products.length-2] : null))
        
        return listProducts;
    }

    getStyledPagination(){
        return styled(Pagination)`
            
        margin-top: 5px;
        
            .page-item .page-link {
                z-index: 1 !important;
                color: ${this.props.paginationFontColor} !important;
                background-color: transparent !important;
                border-color: transparent !important;
            }

            .page-item:hover {
                background-color: #E0E0DF !important;
                border-color: transparent !important;
            }

            .page-item.active .page-link {
                z-index: 1 !important;
                color: white !important;
                background-color: ${this.props.paginationBgColor} !important;
                border-color: transparent !important;
            }
        `;
    }

    getPaginationItems(){
        const items = [];
        if(this.props.pages <= 5 || this.props.currentPage <= 3){
            for(let i = 0; i < Math.min(this.props.pages, 5); i+=1) {
                items.push(<Pagination.Item key={i+1} active={i+1 === this.props.nextPage-1} onClick={(e) => this.props.selectPage(this.state.selectedCategory, i+1)}>{i+1}</Pagination.Item>);
            }
        } else {
            for(let i = this.props.currentPage - 3; i <  Math.min(this.props.pages, this.props.currentPage + 2); i+=1){
                items.push(<Pagination.Item key={i+1} active={i+1 === this.props.nextPage-1} onClick={(e) => this.props.selectPage(this.state.selectedCategory, i+1)}>{i+1}</Pagination.Item>);
            }
        }

        return items;
    }

    render() {
        const products = this.props.products;
        const listProducts = this.getRowsOfProducts(products);
        const StyledPagination = this.getStyledPagination();
        const paginationItems = this.getPaginationItems();

        return (
            <Col className="products-bg">
                <Container fluid>
                    {!this.props.isLoading ? listProducts: (<Loader/>)}
                    {!this.props.isLoading && products.length === 0 ? (<Row className="h-100 justify-content-center">No hay productos disponibles</Row>) : null }
                </Container>
                <Container fluid>
                {this.props.pages > 1 ? (
                    <StyledPagination className='justify-content-center no-border'>
                        <Pagination.First onClick={(e) => this.props.selectPage(this.state.selectedCategory, 1)}/>
                        {this.props.previousPage > 0 ? (<Pagination.Prev onClick={(e) => this.props.selectPage(this.state.selectedCategory, this.props.previousPage)}/>) : null}
                        {paginationItems}
                        {this.props.nextPage <= this.props.pages ? (<Pagination.Next onClick={(e) => { this.setState({...this.state, page: this.props.nextPage}); this.props.selectPage(this.state.selectedCategory, this.props.nextPage)} } />) : null}
                        <Pagination.Last onClick={(e) => this.props.selectPage(this.state.selectedCategory, this.props.pages)} />
                    </StyledPagination>) : null }
                </Container>
            </Col>
        );
    }
}

const mapStateToProps = function(state) {
    return {
      isLoading: state.global.isLoading
    }
}

const mapDispatchToProps = dispatch => ({
    selectPage: (selectedCategory, newPage) => dispatch(selectPage(selectedCategory, newPage)),
    addItemToKart: (kartName, item) => dispatch(addItemToKart(kartName, item)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductList);
