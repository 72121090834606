import {
  IS_LOADING,
  END_LOADING,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  SELECT_PAGE,
  FETCH_SUBCATEGORIES_SUCCESS,
  FETCH_SUBCATEGORIES_GROUP_SUCCESS,
  FETCH_SUBCATEGORIES_FAILURE,
  SELECT_SUBCATEGORY,
  FETCH_BRANDS_SUCCESS,
  FETCH_BRANDS_FAILURE,
  SELECT_BRAND,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAILURE,
  SELECT_TAG,
  ADD_ITEM_TO_KART,
  REMOVE_ITEM_FROM_KART,
  UPDATE_ITEM_KART_AMOUNT,
  EMPTY_KART,
  FETCH_USER_PROFILE_FAILURE,
  FETCH_USER_PROFILE_SUCCESS,
  CREATE_ORDER_FAILURE,
  FETCH_ITEM_SUCCESS,
  FETCH_ITEM_FAILURE,
  CREATE_PAYMENT_PREFERENCE_FAILURE,
  FETCH_ADVERTISEMENTS_SUCCESS,
  REQUEST_ERROR,
  FETCH_ADVERTISEMENT_SUCCESS,
  FETCH_FEATURED_PRODUCTS_SUCCESS,
  FETCH_MESSAGES_SUCCESS,
  SEND_GENERAL_MESSAGE_SUCCESS,
  NEW_CHAT_LISTENER,
  TOOGLE_CHAT,
  OPEN_CHAT,
  FETCH_USER_ORDERS_SUCCESS,
  FETCH_REWARDS_BALANCE_SUCCESS,
  EXCHANGE_REWARDS_SUCCESS,
  EXCHANGE_REWARDS_FAILURE,
  FETCH_REWARDS_CONFIGURATION_SUCCESS,
  NEW_ITEM_QUESTIONS_LISTENER,
  FETCH_ITEM_QUESTIONS_SUCCESS,
  CREATE_ITEM_QUESTION_SUCCESS,
} from "./actionTypes";
import { productService } from '../services/product.service';
import { userService } from '../services/user.service';
import { orderService } from '../services/order.service';
import { advertisementService } from '../services/advertisement.service';
import { chatService } from '../services/chat.service';
import { rewardsService } from '../services/rewards.service';
import { questionsService } from '../services/questions.service';

const requestError = error => ({
  type: REQUEST_ERROR,
  payload: {
      error
  }
});

export const getProducts = (category, page, subcategory = 'all', brand = 'all', tags = 'all') => {
    return dispatch => {
      dispatch(isLoading());
  
      productService.get(category, page, subcategory, brand, tags)
        .then(res => {
          dispatch(fetchProductsSuccess(res.data));
        })
        .catch(err => {
          dispatch(fetchProductsFailure(err.message));
        }).then(() => dispatch(notLoading()));
    };
};

const isLoading = () => ({
  type: IS_LOADING
});

const notLoading = () => ({
  type: END_LOADING
});


const fetchProductsSuccess = ({products, pages, nextPage, previousPage}) => ({
    type: FETCH_PRODUCTS_SUCCESS,
    payload: {
      products,
      pages,
      nextPage,
      previousPage
    }
});

const fetchProductsFailure = error => ({
    type: FETCH_PRODUCTS_FAILURE,
    payload: {
        error
    }
});

export const selectPage = (category, newPage) => {
  return (dispatch, getState) => {
    dispatch({
      type: SELECT_PAGE,
      payload: {
        newPage,
      }
    })
    const state = getState();
    dispatch(getProducts(category, newPage, state.commerce.selectedSubcategory, state.commerce.selectedBrand, state.commerce.selectedTag));
  };
};

export const selectSubcategory = (category, {subcategory}) => {
  return (dispatch, getState) => {
    dispatch({
      type: SELECT_SUBCATEGORY,
      payload: {
        category,
        subcategory,
      }
    })
    const state = getState();
    dispatch(getProducts(category, 1, state.commerce.selectedSubcategory, state.commerce.selectedBrand, state.commerce.selectedTag));
    dispatch(getTags(category, state.commerce.selectedSubcategory));
  };
};

const fetchSubcategoriesSuccess = (subcategories) => ({
  type: FETCH_SUBCATEGORIES_SUCCESS,
  payload: {
    subcategories
  }
});

const fetchSubcategoriesFailure = error => ({
  type: FETCH_SUBCATEGORIES_FAILURE,
  payload: {
      error
  }
});

const fetchTagsSuccess = (tags) => ({
  type: FETCH_TAGS_SUCCESS,
  payload: {
    tags
  }
});

const fetchTagsFailure = error => ({
  type: FETCH_TAGS_FAILURE,
  payload: {
      error
  }
});

const fetchBrandsSuccess = (brands) => ({
  type: FETCH_BRANDS_SUCCESS,
  payload: {
    brands
  }
});

const fetchBrandsFailure = error => ({
  type: FETCH_BRANDS_FAILURE,
  payload: {
      error
  }
});

export const getBrands = (category) => {
  return dispatch => {
    productService.getBrandsByCategory(category).then(res => {
      dispatch(fetchBrandsSuccess(res.data));
    })
    .catch(err => {
      dispatch(fetchBrandsFailure(err.message));
    })
  };
}

const fetchSubcategoriesGroupSuccess = (category, subcategories) => ({
  type: FETCH_SUBCATEGORIES_GROUP_SUCCESS,
  payload: {
    category,
    subcategories
  }
});

export const getSubcategories = (category, group = false) => {
  return dispatch => {
    productService.getSubcategories(category).then(res => {
      if(!group) dispatch(fetchSubcategoriesSuccess(res.data));
      else dispatch(fetchSubcategoriesGroupSuccess(category, res.data))
    })
    .catch(err => {
      dispatch(fetchSubcategoriesFailure(err.message));
    })
  };
}

export const getTags = (category, subcategory) => {
  return dispatch => {
    productService.getTags(category, subcategory).then(res => {
      dispatch(fetchTagsSuccess(res.data));
    })
    .catch(err => {
      dispatch(fetchTagsFailure(err.message));
    })
  };
}

export const selectBrand = (category, {brand}) => {
  return (dispatch, getState) => {
    dispatch({
      type: SELECT_BRAND,
      payload: {
        brand
      }
    })
    const state = getState();
    dispatch(getProducts(category, 1, state.commerce.selectedSubcategory, state.commerce.selectedBrand, state.commerce.selectedTag));
  };
};

export const selectTag = (category, {tag}) => {
  return (dispatch, getState) => {
    dispatch({
      type: SELECT_TAG,
      payload: {
        tag
      }
    })
    const state = getState();
    dispatch(getProducts(category, 1, state.commerce.selectedSubcategory, state.commerce.selectedBrand, state.commerce.selectedTag));
  };
};

export const addItemToKart = (kartName, item) => {
  return dispatch => {
    dispatch({
      type: ADD_ITEM_TO_KART,
      payload: {
        kartName,
        item,
      }
    })
  }
}

export const updateItemKartAmount = (kartName, item, amount) => {
  return dispatch => {
    dispatch({
      type: UPDATE_ITEM_KART_AMOUNT,
      payload: {
        kartName,
        item,
        amount,
      }
    })
  }
}

export const removeItemFromKart = (kartName, itemId) => {
  return dispatch => {
    dispatch({
      type: REMOVE_ITEM_FROM_KART,
      payload: {
        kartName,
        itemId,
      }
    })
  }
}

export const emptyKart = (kartName) => {
  return dispatch => {
    dispatch({
      type: EMPTY_KART,
      payload: {
        kartName
      }
    })
  }
}

const fetchProfileFailure = error => ({
  type: FETCH_USER_PROFILE_FAILURE,
  payload: {
      error
  }
});

const fetchProfileSuccess = (profile) => ({
  type: FETCH_USER_PROFILE_SUCCESS,
  payload: {
    profile
  }
});

export const getProfile = () => {
  return dispatch => {
    userService.getProfile()
      .then(res => {
        if(res.data.id){ 
          dispatch(fetchProfileSuccess(res.data));
          dispatch(getUserOrders(res.data.id));
          // dispatch(getRewardsBalance(res.data.id))
        }
        else throw Object.assign(new Error("Cant get user profile"),{ code: 402 }
       );
      })
      .catch(err => {
        dispatch(fetchProfileFailure(err.message));
      })
  };
};

const createOrderFailure = error => ({
  type: CREATE_ORDER_FAILURE,
  payload: {
      error
  }
});

export const createOrder = (orderPayload) => {
  return dispatch => {
    dispatch(isLoading());
    orderService.create(orderPayload)
      .then(res => {
        if(res.data && res.data.total > 0) dispatch(createPaymentPreference(res.data));
        else window.location.href = `/success?collection_status=approved&external_reference=${res.data.orderId}`;
      })
      .catch(err => {
        dispatch(createOrderFailure(err.message));
      });
  }
}

const fetchItemSuccess = (item) => ({
  type: FETCH_ITEM_SUCCESS,
  payload: {
    ...item
  }
});

const fetchItemFailure = (error) => ({
  type: FETCH_ITEM_FAILURE,
  payload: {
    error
  }
});

export const getItemDetail = (itemId) => {
  return dispatch => {
    dispatch(isLoading());
    productService.getItemDetail(itemId)
      .then(res => {
        dispatch(fetchItemSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchItemFailure(err.message));
      }).then(() => dispatch(notLoading()));
  }
}

const createPaymentPreferenceFailure = (error) => ({
  type: CREATE_PAYMENT_PREFERENCE_FAILURE,
  payload: {
    error
  }
});

export const createPaymentPreference = (orderPayload) => {
  const preferencePayload = {};
  preferencePayload.orderId = orderPayload.orderId;
  preferencePayload.email = orderPayload.email;
  preferencePayload.items = orderPayload.items.map(it => {
    return {
      id: it.id,
      price: parseFloat(it.price).toFixed(2),
      quantity: it.quantity,
      title: it.model,
      currency: "MXN"
    }
  });
  
  return dispatch => {
    orderService.createPaymentPreference(preferencePayload)
      .then(res => {
        window.location.href = res.data.url;
        //TODO: validate if the url is not null
      })
      .catch(err => {
        dispatch(createPaymentPreferenceFailure(err.message));
      });
  }
};

export const updatePaymentStatus = (paymentPayload) => {
  return dispatch => {
    orderService.updatePaymentStatus(paymentPayload)
      .catch(err => {
        dispatch(createPaymentPreferenceFailure(err.message));
      })
  }
}

export const searchProducts = (text) => {
  return dispatch => {
    dispatch(isLoading());

    productService.search(text)
      .then(res => {
        dispatch(fetchProductsSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchProductsFailure(err.message));
      }).then(() => dispatch(notLoading()));
  };
};

const fetchAdvertisementsSuccess = (advertisements) => ({
  type: FETCH_ADVERTISEMENTS_SUCCESS,
  payload: {
    advertisements
  }
});


export const getAdvertisements = () => {
  return dispatch => {
    dispatch(isLoading());

    advertisementService.getAll()
      .then(res => {
        dispatch(fetchAdvertisementsSuccess(res.data));
      })
      .catch(err => {
        dispatch(requestError(err.message));
      }).then(() => dispatch(notLoading()));
  };
};

const fetchAdvertisementSuccess = (advertisement) => ({
  type: FETCH_ADVERTISEMENT_SUCCESS,
  payload: {
    advertisement
  }
});

export const getAdvertisementById = (advertisementId) => {
  return dispatch => {
    dispatch(isLoading());

    advertisementService.getById(advertisementId)
      .then(res => {
        dispatch(fetchAdvertisementSuccess(res.data));
      })
      .catch(err => {
        dispatch(requestError(err.message));
      }).then(() => dispatch(notLoading()));
  };
};

const fetchFeaturedProductsSuccess = (featuredProducts) => ({
  type: FETCH_FEATURED_PRODUCTS_SUCCESS,
  payload: {
    featuredProducts: featuredProducts.products
  }
});

export const getFeaturedProducts = () => {
  return dispatch => {
    dispatch(isLoading());

    productService.getFeatured()
      .then(res => {
        dispatch(fetchFeaturedProductsSuccess(res.data));
      })
      .catch(err => {
        dispatch(requestError(err.message));
      }).then(() => dispatch(notLoading()));
  };
};

const fetchItemQuestionsSuccess = (questions) => ({
  type: FETCH_ITEM_QUESTIONS_SUCCESS,
  payload: {
    questions
  }
});

export const getItemQuestions = (itemId, type) => {
  return dispatch => {
    dispatch(newItemQuestionsListener(
      questionsService.getQuestionsSnapshot(type).then(questionsDoc => {
        const questions = questionsDoc.data() && questionsDoc.data()[itemId] ? questionsDoc.data()[itemId].questions : [];
        if(questions.length){
          dispatch(fetchItemQuestionsSuccess(questions));
        }
        }, err => {
          dispatch(requestError(err));
      }))
    );
  };
};

const createItemQuestionSuccess = (question) => ({
  type: CREATE_ITEM_QUESTION_SUCCESS,
  payload: {
    question
  }
});

export const createItemQuestion = (itemId, type, question) => {
  return dispatch => {
      questionsService.createQuestion(itemId, type, question).then(
        () => {
          dispatch(createItemQuestionSuccess(question));
          dispatch(getItemQuestions(itemId, type));
        }
      ).catch(err => {
        dispatch(requestError(err.message));
      });
  };
};

const fetchMessagesSuccess = (messages) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: {
    messages
  }
});

export const getGeneralChatMessages = (userId) => {
  return dispatch => {
    dispatch(newChatListener(
      chatService.getGeneralMessagesSnapshot(userId).onSnapshot(snapshot => {
        let messages = [];
        snapshot.forEach(
          doc => messages.push(doc.data())
        );
        if(messages.length){
          dispatch(fetchMessagesSuccess(messages));
        }
        }, err => {
          dispatch(requestError(err));
      }))
    );
  };
};

const sendGeneralMessageSuccess = (message) => ({
  type: SEND_GENERAL_MESSAGE_SUCCESS,
  payload: {
    message
  }
});

export const sendGeneralMessage = (userId, message) => {
  return dispatch => {
      chatService.sendGeneralMessage(userId, message).then(
        () => {
          dispatch(sendGeneralMessageSuccess(message));
        }
      ).catch(err => {
        dispatch(requestError(err.message));
      });
  };
};

const newChatListener = (chatListener) => ({
  type: NEW_CHAT_LISTENER,
  payload: {
    chatListener
  }
});

const newItemQuestionsListener = (questionsListener) => ({
  type: NEW_ITEM_QUESTIONS_LISTENER,
  payload: {
    questionsListener
  }
});

export const toogleChat = () => {
  return dispatch => (
    dispatch({
      type: TOOGLE_CHAT
    })
  )
}

export const openChatAndSendMessage = (userId, message) => {
  return dispatch => {
    dispatch({
      type: OPEN_CHAT
    })
    dispatch(
      sendGeneralMessage(userId, message)
    )
  }
}

const fetchUserOrdersSuccess = (orders) => ({
  type: FETCH_USER_ORDERS_SUCCESS,
  payload: {
    orders
  }
});

export const getUserOrders = (userId) => {
  return dispatch => {
    orderService.getUserOrders(userId).then(
      (res) => {
        dispatch(fetchUserOrdersSuccess(res.data));
      }
    ).catch(err => {
      dispatch(requestError(err.message));
    });
  }
}

const fetchRewardsBalanceSuccess = (rewards) => ({
  type: FETCH_REWARDS_BALANCE_SUCCESS,
  payload: {
    rewards
  }
});

export const getRewardsBalance = (userId) => {
  return dispatch => {
    rewardsService.getRewardsBalance(userId).then(
      (res) => {
        dispatch(fetchRewardsBalanceSuccess(res.data));
      }
    ).catch(err => {
      dispatch(requestError(err.message));
    });
  }
}

const exchangeRewardsSuccess = (response) => ({
  type: EXCHANGE_REWARDS_SUCCESS,
  payload: {
    response
  }
});

const exchangeRewardsFailure = (err) => ({
  type: EXCHANGE_REWARDS_FAILURE,
  payload: {
    err
  }
});

export const exchangeRewards = (userId, ticketCode, points) => {
  return dispatch => {
    rewardsService.exchangeRewards(userId, ticketCode, points).then(
      (res) => {
        dispatch(exchangeRewardsSuccess(res.data));
        dispatch(getRewardsBalance(userId));
      }
    ).catch(err => {
      dispatch(exchangeRewardsFailure(err));
    });
  }
}

const fetchRewardsConfigurationsSuccess = (configurations) => ({
  type: FETCH_REWARDS_CONFIGURATION_SUCCESS,
  payload: {
    configurations
  }
});

export const getRewardsConfigurations = () => {
  return dispatch => {
    rewardsService.getRewardsConfigurations().then(
      (res) => {
        dispatch(fetchRewardsConfigurationsSuccess(res.data));
      }
    ).catch(err => {
      dispatch(requestError(err.message));
    });
  }
}