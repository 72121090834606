import React, {useState} from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';


const RewardsModal = ({children}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const OpenModalTrigger = React.cloneElement(children, {
      onClick: handleShow,
    });

    const StyledPaymentMethodRow = styled(Row)`
      padding: 20px 0px;
      
      .payment-method-icon {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      .payment-logo {
        padding: 0px 10px;
      }

      .logo-large {
        max-width: 100px;
      }
    `;

    return (
      <>
        {OpenModalTrigger}
        <Modal
          size="xl"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Sonopuntos
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container style={{padding: "0px 20px"}}>
                <StyledPaymentMethodRow>
                  <Col md={2} xs={12}>
                    <img className="payment-method-icon" style={{height: "32px", width: "auto"}} src="https://i.postimg.cc/VsqvxmWj/sonopuntos.png" alt="mercado pago logo"/>
                  </Col>
                  <Col md={10} xs={12}>
                    <h5>Paga con Sonopuntos</h5>
                    <p>
                      <ul>
                        <li>Las recompensas han llegado a Sonoritmo con Sonopuntos! a partir del 17 de septiembre del 2019</li>
                        <li>Todas tus compras en sucursales y en nuestro e-commerce ya generan puntos!.</li>
                        <li>Solo necesitas ingresar a Sonoritmo.com.mx y con tu ticket de compra no mayor a 30 días un ejecutivo te atenderá en nuestro chat para abonarlos a tu cuenta</li>
                        <li>Con tus puntos podrás realizar compras de todos nuestros productos en linea con tu cuenta</li>
                        <li>Cada punto equivale a $.20 centavos</li>
                      </ul>
                    </p>
                  </Col>
                </StyledPaymentMethodRow>
              </Container>
          </Modal.Body>
        </Modal>
      </>
    );
}

export default RewardsModal;
