import React, { Component } from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Loginbar from '../../shared/professional/Loginbar/Loginbar';
import Navigation from '../../shared/sonoritmo/Navigation/Navigation';
import Footer from '../../shared/footer/footer';
import { updatePaymentStatus } from '../../redux/actions';
import queryString from 'query-string'

class PaymentResponse extends Component {
    
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount(){
        const paymentPayload = queryString.parse(this.props.location.search);
        this.props.updatePaymentStatus(paymentPayload);
    }

    isSuccessResponse(){
        const { collection_status } = queryString.parse(this.props.location.search);
        const pathname = this.props.location.pathname;
        
        return collection_status === "approved" && pathname === "/success";
    }

    isPendingResponse(){
        const { collection_status } = queryString.parse(this.props.location.search);
        const pathname = this.props.location.pathname;
        
        return collection_status === "pending" && pathname === "/pending";
    }

    isErrorResponse(){
        const pathname = this.props.location.pathname;
        
        return pathname === "/error";
    }

    render() {
        const { external_reference } = queryString.parse(this.props.location.search);
        return (
            <Container fluid>
                <Row className="sonoritmo-bg-color">
                    <Loginbar name="Alberto Ayala Capetillo" customer_id="442424"/>
                    <Navigation category={"items"} auth={this.props.auth}/>
                </Row>
                <Row className="products-content">
                    <Col md="12" hidden={!this.isSuccessResponse()}>
                        <i className="fa fa-5x fa-check-circle icon-success"></i>
                        <p>Tu compra fue exitosa</p>
                        <p>Revisa tu email constantemente, te estaremos actualizando de cualquier novedad en tu pedido</p>
                        <p>Orden #{external_reference}</p>
                    </Col>
                    <Col md="12"  hidden={!this.isPendingResponse()}>
                        <i className="fa fa-5x fa-info-circle icon-info"></i>
                        <p>Estamos esperando la acreditacion de tu pago</p>
                        <p>Nos pondremos en contacto contigo si no se acredita en los proximos 2 dias habiles</p>
                        <p>Orden #{external_reference}</p>
                    </Col>
                    <Col md="12" hidden={!this.isErrorResponse()}>
                        <i className="fa fa-5x fa-times-circle icon-error"></i>
                        <p>No pudimos procesar tu pago</p>
                        <p>Nos pondremos en contacto contigo para ofrecerte otras opciones de pago</p>
                        <p>Orden #{external_reference}</p>
                    </Col>
                    <Col>
                        <p>Puedes ver tus ordenes en la seccion <b>Mis compras</b></p>
                        <a href="/ordenes">
                            <Button variant="primary">Ir a <b>Mis compras</b></Button>
                        </a>
                    </Col>
                </Row>
                <Footer/> 
            </Container>
        );
    }
}

const mapStateToProps = function(state) {
    return {
      profile: state.global.profile
    }
}
    
const mapDispatchToProps = dispatch => ({
  updatePaymentStatus: (paymentPayload) => dispatch(updatePaymentStatus(paymentPayload))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentResponse);

