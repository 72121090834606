import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const getQuestionsSnapshot = (type) => {
    firebase.auth().signInAnonymously().catch(function(error) {
        console.error(error);
    });
    
    const db = firebase.firestore();
    
    return db.doc(`questions/${type}`).get();
}

const createQuestion = (itemId, type, question) => {
    firebase.auth().signInAnonymously().catch(function(error) {
        console.error(error);
    });
    
    var db = firebase.firestore();
    db.doc(`questions/${type}`).get().then((doc) => 
        {
            let questionsData = doc.data();
            if(!questionsData) questionsData = {};
            if(!questionsData[itemId+'']) questionsData[itemId+''] = {questions: []};
            questionsData[itemId+''].questions = [...questionsData[itemId+''].questions, question];
            if(questionsData[itemId+''].questions.length === 1) db.doc(`questions/${type}`).set(questionsData);
            else db.doc(`questions/${type}`).update(questionsData);
        }
    );
    return db.doc(`questions/${type}`).get()
}

export const questionsService = {
    getQuestionsSnapshot,
    createQuestion
}
