import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';

import CreditPriceMonths
  from '../../shared/creditPriceMonths/CreditPriceMonths';
import {
  currencyFormat,
  urlFormat,
} from '../../utils/format';
import PremiumProductModal from '../premiumProductModal/PremiumProductModal';

const REACT_APP_INFORMATIVE_MODE = process.env.REACT_APP_INFORMATIVE_MODE === 'true';

class ProductCard extends Component {

    constructor(...args) {
        super(...args);
        this.state = {
            amount: 1
        };
    }

    itemHasDiscount() {
        return this.props.price_with_discount 
            && parseFloat(this.props.price_with_discount) < parseFloat(this.props.price);
    }

    getFinalPrice() {
        return !this.itemHasDiscount() ? this.props.price : this.props.price_with_discount;
    }

    getItemType(){
        return !this.props.product.items ? 'item' : 'pack';
    }
    
    render() {
        let price = this.getFinalPrice();

        return (
            <Row className="product-item align-items-center">
                <Col md={12} className="responsive-image-wrapper border-bottom ">
                    <Nav.Link href={`/${this.getItemType()}/${urlFormat(this.props.product.model)}/${this.props.product.id}`} >
                        <img className="responsive-image clickable" src={this.props.photos[0]} alt="not found"></img>
                    </Nav.Link>
                </Col>
                <Col md={12} className="text-align-left item-padding">
                    <Row className="sold-items" style={{height: "20px"}}>
                        { this.props.product.sold_units ? (`${this.props.product.sold_units} ${this.props.product.sold_units === 1 ? 'Vendido' : 'Vendidos'}`) : null }
                    </Row>
                    <Row className="my-1" style={{ height: "48px", overflow: "hidden"}}>{this.props.product.title ? this.props.product.title + " - " : ""} {this.props.model}</Row>
                    <Row>
                        <Col md="6" xs="6" className="no-margin no-padding">
                            <Row className="final-price no-margin no-padding" hidden={price < 0.1 || this.props.product.items}>${currencyFormat(price)}</Row>
                            <Row className="price-whitout-discount no-margin no-padding" hidden={price < 0.1 || this.props.product.items}>{ this.itemHasDiscount() ? `$ ${currencyFormat(this.props.price)}` : null }</Row>
                        </Col>
                        <Col md="6" xs="6" className="vertical-align center-text" hidden={price < 0.1 || this.props.product.items}>
                            <OverlayTrigger
                                key={"right"}
                                placement={"right"}
                                overlay={
                                    <Tooltip id={`tooltip-right`}>
                                        Envío Gratis
                                    </Tooltip>
                                }
                                >
                                <i className="fa fa-2x fa-truck icon-success"></i>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                    <CreditPriceMonths price={price} hasItems={this.props.product.items} hasInstallments={this.props.product.hasInstallments}/>
                    { (price  < 0.1 || this.props.product.items) && (<PremiumProductModal model={this.props.model}/>)}
                    <Row>
                        <h6 className="clickable show-detail">
                            <Nav.Link href={`/${this.getItemType()}/${urlFormat(this.props.product.model)}/${this.props.product.id}`}>Detalle</Nav.Link>
                        </h6>
                    </Row>
                    <Row hidden={this.props.product.items}><h6>Disponible: {this.props.stock}</h6></Row>
                    <Row hidden={!this.props.product.items}><h6>Disponibilidad inmediata</h6></Row>
                    <Row className="centered-content" hidden={price < 0.1 || this.props.product.items}>
                        {!REACT_APP_INFORMATIVE_MODE &&
                        <Button variant="dark" onClick={ e => this.props.addToKartHandler("kart", {...this.props.product, amount: 1})}>
                            Agregar <i className="fa fa-cart-plus clickable"></i>
                        </Button>
                        }
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default ProductCard;
