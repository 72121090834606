import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Loginbar extends Component {
    render() {
        return (
            <Row className="login-bar hidden">
                <Col className="right-border">Bienvenido al portal de clientes</Col>
                <Col className="clickable">Mi cuenta</Col>
                <Col xs={2}></Col>
                <Col className="right-border">{this.props.name} - {this.props.customer_id}</Col>
                <Col className="clickable">Cerrar sesión</Col>
            </Row>
        );
    }
}

export default Loginbar;
