import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk';
import { persistStore, persistCombineReducers } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import rootReducer from '../reducers/index'

import logger from 'redux-logger'

const persistConfig = {
  key: 'root',
  storage: storageSession,
  blacklist: ['commerce'],
}

const persistedReducer = persistCombineReducers(persistConfig, rootReducer)
let store = createStore(persistedReducer, applyMiddleware(logger, thunk))
let persistor = persistStore(store)

export { store, persistor }