import React, { Component } from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { Row, Col, Card, ListGroup, Button, Modal } from 'react-bootstrap';
import Navigation from '../../../shared/sonoritmo/Navigation/Navigation';
import Footer from '../../../shared/footer/footer';
import ReactGA from 'react-ga';
import { getUserOrders, openChatAndSendMessage } from '../../../redux/actions';
import { dateToHumanFormat, mapStoreCodeToAddress } from '../../../utils/format';

const tranlateOrderState = (state) => {
  switch (state) {
    case "created":
      return "Preparando productos"
    case "in_progress":
      return "En camino"
    case "finished":
      return "Entregada"
    case "canceled":
        return "Cancelada"
    default:
      break;
  }
}

const getOrderStateColor = (state) => {
  switch (state) {
    case "created":
      return "blue"
    case "in_progress":
      return "#39b54a"
    case "finished":
      return "gray"
    case "canceled":
        return "red"
    default:
      break;
  }
}

const OrderState = ({state}) => (
  <>
    <i style={{color: getOrderStateColor(state)}} className="fa fa-dot-circle-o"></i> {tranlateOrderState(state)}
  </>
)

const OrderCard = ({id, createdAt, updatedAt, state, items, handleTrackButton}) => (
  
    <Col style={{backgroundColor: "white", marginTop:"10px"}}>
        <Row>
            <Col md="4" style={{fontSize: "12px", textAlign: "left"}}>
                Orden #{id} Creada: {dateToHumanFormat(createdAt, "a las")}
            </Col>
            <Col md="4" style={{fontSize: "12px", textAlign: "left"}}>
                { updatedAt && <> Ultima actualizacion: {dateToHumanFormat(updatedAt, "a las")} </> }
            </Col>
            <Col md="4" style={{fontSize: "16px", textAlign: "center"}}>
                <OrderState state={state}/>
            </Col>
            <Col md="8" xs="12" style={{paddingBottom: "12px"}}>
                <Card style={{minHeight: "100%"}}>
                    <ListGroup variant="flush">
                      { items.map(item => 
                        <ListGroup.Item key={item.model} style={{fontSize: "13px"}}>
                          <Row>
                            <Col>
                              <img alt="product-pic" src={item.picture} style={{maxHeight: "60px", paddingRight: "5px"}}></img>
                            </Col>
                            <Col>
                              {item.description}
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      )}
                    </ListGroup>
                </Card>
            </Col>
            <Col md="4" xs="12" style={{paddingBottom: "12px", marginTop: "auto", marginBottom: "auto"}}>
                <Button onClick={_ => handleTrackButton(id)} disabled={!(state === "in_progress")} variant="success" block>Rastrear</Button>
                {false && <Button variant="outline-primary" block>Ver detalles</Button>}
                {false && <Button variant="outline-primary" block>Solicitar factura</Button>}
            </Col>
        </Row>
    </Col>  
);

class Orders extends Component {

  constructor(props) {
    super(props);
    this.props = props;
  
    this.state = {
      isLoading: false,
      showModal: false,
      selectedOrder: null,
    }
    
    ReactGA.pageview(`${this.props.location.pathname}${this.props.location.search}`);
    this.handleTrackButton = this.handleTrackButton.bind(this);
    this.handleTrackOrderHelpButton = this.handleTrackOrderHelpButton.bind(this);
    this.toogleModal = this.toogleModal.bind(this);
  }

  componentDidMount() {
    this.props.getUserOrders(this.props.profile.id);
  }

  handleTrackButton(orderId) {
    const order = this.props.orders.find((order) => order.id === orderId);
    this.toogleModal(orderId, order);
  }

  handleTrackOrderHelpButton() {
    const message = `Hola!, ¿me puedes ayudar con mi pedido #${this.state.selectedOrder.id}?, tengo dudas acerca del envío`;
    const helpMessage = {
        id: new Date().getTime(),
        name: this.props.profile.first_name,
        timestamp: new Date(Date.now()-(new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 19).replace('T', ' '),
        type: "customer",
        message,
        metadata: {
            section: window.location.pathname,
            profilePicture: this.props.profile.picture
        },
    }
    this.props.openChatAndSendMessage(this.props.profile.id, helpMessage);
    this.toogleModal();
  }

  toogleModal(_, order){
    this.setState({...this.state, showModal: !this.state.showModal, selectedOrder: order})
  }

  getTrackingURL(){
    let url = "";
    
    if(this.state.selectedOrder && parseInt(this.state.selectedOrder.has_direct_tracking)) //eslint-disable-next-line
      url = this.state.selectedOrder.tracking_url_template.replace("${tracking_number}", this.state.selectedOrder.tracking_number);
    else if(this.state.selectedOrder && !parseInt(this.state.selectedOrder.has_direct_tracking)) 
      url = this.state.selectedOrder.tracking_url_template

    return url;
  }

  render() {

    const cards = this.props.orders.map(order => <OrderCard
        key={order.id}
        id={order.id}
        createdAt={order.created_at}
        updatedAt={order.updated_at}
        state={order.state}
        items={order.items}
        handleTrackButton={this.handleTrackButton}
      />);

    const url = this.getTrackingURL();

    return (
        <Container fluid>
            <Row className="sonoritmo-bg-color">
              <Navigation auth={this.props.auth}/>
            </Row>
            <Row>
              <Container>
                {this.props.orders.length === 0 && !this.props.isLoading && <p>Aún no has realizado ninguna compra</p>}
              </Container>
            </Row>
            <Row className="h-100 products-content">
              <Container>
                {cards}
              </Container>
            </Row>
            <Footer/>
            {this.state.selectedOrder && 
              <Modal show={this.state.showModal} onHide={this.toogleModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Rastrear mi paquete</Modal.Title>
                </Modal.Header>
                { this.state.selectedOrder.name !== 'PERSONAL' &&
                  <Modal.Body>
                    Tu paquete fue enviado por <b>{this.state.selectedOrder.name}</b><br/>
                    Este es tu numero de guia: <b>{this.state.selectedOrder.tracking_number}</b><br/><br/>
                    Para rastrear tu paquete copia tu numero de guia y ve a la página de rastreo de {this.state.selectedOrder.name}
                  </Modal.Body>
                }
                { this.state.selectedOrder.name === 'PERSONAL' &&
                  <Modal.Body>
                    {parseInt(this.state.selectedOrder.in_store) === 1 &&
                    <>
                      Te haremos la entrega personalmente, elegiste recoger en nuestra sucursal: <br/>
                      <b>{mapStoreCodeToAddress(this.state.selectedOrder.in_store_branch)}<br/></b>
                    </>}
                    {parseInt(this.state.selectedOrder.in_store) === 0 &&
                    <>Te haremos la entrega personalmente ya que estas dentro de nuestra área de cobertura<br/></>}
                    Este es tu numero de confirmacion: <b>{this.state.selectedOrder.tracking_number}</b><br/><br/>
                    Recuerda proporcionarle tu número de confirmación a nuestro repartidor
                  </Modal.Body>
                }
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.toogleModal}>
                    Cerrar
                  </Button>
                  { this.state.selectedOrder.name !== 'PERSONAL' &&
                    <a rel="noopener noreferrer" href={url} target="_blank">
                      <Button variant="primary">
                        Ir a la pagina de {this.state.selectedOrder.name}
                      </Button>
                    </a>
                  }
                  { this.state.selectedOrder.name === 'PERSONAL' &&
                    <Button variant="primary" onClick={this.handleTrackOrderHelpButton}>
                      Necesito ayuda
                    </Button>
                  }
                </Modal.Footer>
            </Modal>} 
        </Container>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    isLoading: state.global.isLoading,
    orders: state.global.orders,
    profile: state.global.profile,
  }
}
  
const mapDispatchToProps = dispatch => ({
    getUserOrders: (userId) => dispatch(getUserOrders(userId)),
    openChatAndSendMessage: (userId, message) => dispatch(openChatAndSendMessage(userId, message))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Orders);
