import React, {useState} from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';


const SecurityModal = ({children}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const OpenModalTrigger = React.cloneElement(children, {
      onClick: handleShow,
    });

    const StyledPaymentMethodRow = styled(Row)`
      padding: 20px 0px;
      
      .payment-method-icon {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      .payment-logo {
        padding: 0px 10px;
      }

      .logo-large {
        max-width: 100px;
      }
    `;

    return (
      <>
        {OpenModalTrigger}
        <Modal
          size="xl"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Politica de compra protegida
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container style={{padding: "0px 20px"}}>
              <StyledPaymentMethodRow>
                <Col md={2} xs={2}>
                  <img className="payment-method-icon" src="https://http2.mlstatic.com/resources/frontend/homes-korriban/assets/images/ecosystem/protected.svg" alt="mercado pago logo"/>
                </Col>
                <Col md={10} xs={10}>
                  <p>
                    Garantía limitada de devolución o cambio dentro de los primeros 10 días naturales a partir del momento en que recibes el producto.<br/><br/>
                    A) Para solicitar reembolso:<br/>
                      <ul>
                        <li>El producto debe contar con todos sus accesorios, manuales y documentacion, empaque original y en perfectas condiciones físicas (no marcas de uso).</li>
                        <li>El reembolso se realiza 2 días hábiles después de haberlo recibido en nuestra bodega.</li>
                      </ul>
                      <br/>
                    B) Para solicitar cambio:<br/>
                      <ul>
                        <li>En caso de que el producto llegue golpeado y/o no funcione adecuadamente se te enviara uno nuevo sin costo 2 días hábiles después de recibir el producto a nuestra bodega.</li>
                      </ul>
                  </p>
                </Col>
              </StyledPaymentMethodRow>
            </Container>
          </Modal.Body>
        </Modal>
      </>
    );
}

export default SecurityModal;
