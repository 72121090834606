import {
  REQUEST_ERROR,
  FETCH_PRODUCTS_SUCCESS,
  SELECT_PAGE,
  FETCH_SUBCATEGORIES_SUCCESS,
  FETCH_SUBCATEGORIES_GROUP_SUCCESS,
  SELECT_SUBCATEGORY,
  FETCH_BRANDS_SUCCESS,
  SELECT_BRAND,
  SELECT_TAG,
  FETCH_TAGS_SUCCESS,
  CREATE_ORDER_SUCCESS,
  FETCH_ITEM_SUCCESS,
  FETCH_ADVERTISEMENTS_SUCCESS,
  FETCH_ADVERTISEMENT_SUCCESS,
  FETCH_FEATURED_PRODUCTS_SUCCESS,
  FETCH_REWARDS_BALANCE_SUCCESS,
  EXCHANGE_REWARDS_FAILURE,
  EXCHANGE_REWARDS_SUCCESS,
  FETCH_REWARDS_CONFIGURATION_SUCCESS,
  CREATE_ITEM_QUESTION_SUCCESS,
  FETCH_ITEM_QUESTIONS_SUCCESS
} from "../actionTypes";
import { toast } from 'react-toastify';

const initialState = {
  products: [],
  subcategories: [],
  brands: [],
  tags: [],
  selectedSubcategory: "all",
  selectedBrand: "all",
  selectedTag: "all",
  product: null,
  advertisements: [],
  advertisement: null,
  featuredProducts: [],
  subcategoryGroups: {},
  rewards: {},
  rewardsConfigurations: [],
  questions: [],
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_ERROR: {
      console.error(action.payload);
      return state;
    }
    case FETCH_PRODUCTS_SUCCESS: {
      let updatedState = {
        ...state
      }
      updatedState.products = action.payload.products;
      updatedState.nextPage = action.payload.nextPage;
      updatedState.previousPage = action.payload.previousPage;
      updatedState.pages = action.payload.pages;
      
      return updatedState
    }
    case SELECT_PAGE: {
      let updatedState = {
        ...state
      }
      updatedState.page = action.payload.newPage;
      updatedState.nextPage = action.payload.newPage + 1;
      updatedState.previousPage = action.payload.newPage - 1;

      return updatedState;
    }
    case FETCH_SUBCATEGORIES_SUCCESS: {
      let updatedState = {
        ...state
      }
      updatedState.subcategories = action.payload.subcategories;

      return updatedState;
    }
    case FETCH_SUBCATEGORIES_GROUP_SUCCESS: {
      let subcategoryGroups = {...state.subcategoryGroups}
      subcategoryGroups[action.payload.category] = action.payload.subcategories;
      let updatedState = {
        ...state,
        subcategoryGroups
      }
      return updatedState;
    }
    case SELECT_SUBCATEGORY: {
      let updatedState = {
        ...state
      }
      if(state.selectedSubcategory === action.payload.subcategory){
        updatedState.selectedSubcategory = "all";
      } else {
        updatedState.selectedSubcategory = action.payload.subcategory;
      }

      updatedState.selectedTag = "all";

      return updatedState;
    }
    case SELECT_BRAND: {
      let updatedState = {
        ...state
      }
      if(state.selectedBrand === action.payload.brand){
        updatedState.selectedBrand = "all";
      } else {
        updatedState.selectedBrand = action.payload.brand;
      }

      return updatedState;
    }
    case SELECT_TAG: {
      let updatedState = {
        ...state
      }
      if(state.selectedTag === action.payload.tag){
        updatedState.selectedTag = "all";
      } else {
        updatedState.selectedTag = action.payload.tag;
      }

      return updatedState;
    }
    case FETCH_BRANDS_SUCCESS: {
      let updatedState = {
        ...state
      }
      updatedState.brands = action.payload.brands;

      return updatedState;
    }
    case FETCH_TAGS_SUCCESS: {
      let updatedState = {
        ...state
      }
      updatedState.tags = action.payload.tags;

      return updatedState;
    }
    case FETCH_ITEM_SUCCESS: {
      let updatedState = {
        ...state
      }
      updatedState.product = action.payload;

      return updatedState;
    }
    case CREATE_ORDER_SUCCESS: {
      toast.info("Compra exitosa, en breve recibiras un correo de confirmación", {autoClose: false});
      break;
    }
    case FETCH_ADVERTISEMENTS_SUCCESS: {
      let updatedState = {
        ...state,
        advertisements: action.payload.advertisements
      }

      return updatedState;
    }
    case FETCH_ADVERTISEMENT_SUCCESS: {
      let updatedState = {
        ...state,
        advertisement: action.payload.advertisement
      }

      return updatedState;
    }
    case FETCH_FEATURED_PRODUCTS_SUCCESS: {
      let updatedState = {
        ...state,
        featuredProducts: action.payload.featuredProducts
      }

      return updatedState;
    }
    case FETCH_REWARDS_BALANCE_SUCCESS: {
      let updatedState = {
        ...state,
        rewards: action.payload.rewards
      }

      return updatedState;
    }
    case FETCH_REWARDS_CONFIGURATION_SUCCESS: {
      let updatedState = {
        ...state,
        rewardsConfigurations: action.payload.configurations
      }

      return updatedState;
    }
    case EXCHANGE_REWARDS_SUCCESS: {
      toast.success("Tus puntos fueron agregados");
      
      return state;
    }
    case EXCHANGE_REWARDS_FAILURE: {
      toast.error("Ticket invalido");
      
      return state;
    }
    case CREATE_ITEM_QUESTION_SUCCESS: {
      toast.success("Tu pregunta fue enviada correctamente");
      
      return state;
    }
    case FETCH_ITEM_QUESTIONS_SUCCESS: {
      const newState = Object.assign({}, state);
      newState.questions = [...action.payload.questions];
      
      return newState;
    }
    default:
      return state;
  }
}
