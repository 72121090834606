// import { API_URL, BUSINESSBAY_API } from '../configuration';
import axios from 'axios';
const API_URL = "https://bbay.icu/cms/api/collections/get/HOME_SECTIONS?token=3021309455aca2651fe6a808202b27"

function getHomeConfig() {
    return axios.get(API_URL);
}

export const cmsService = {
    getHomeConfig
};