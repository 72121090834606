import React, {useState} from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import visa from '../../assets/images/visa.png';
import mastercard from '../../assets/images/mastercard.png';
import amex from '../../assets/images/amex.png';
import santander from '../../assets/images/santander.png';
import bbva from '../../assets/images/bbva.png';
import citibanamex from '../../assets/images/citibanamex.png';
import oxxo from '../../assets/images/oxxo.svg';
import styled from 'styled-components';


const PaymentMethodsModal = ({children}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const OpenModalTrigger = React.cloneElement(children, {
      onClick: handleShow,
    });

    const StyledPaymentMethodRow = styled(Row)`
      padding: 20px 0px;
      
      .payment-method-icon {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      .payment-logo {
        padding: 0px 10px;
      }

      .logo-large {
        max-width: 100px;
      }
    `;

    return (
      <>
        {OpenModalTrigger}
        <Modal
          size="xl"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Medios de pago
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container style={{padding: "0px 20px"}}>
              <StyledPaymentMethodRow>
                <Col md={2} xs={2}>
                  <img className="payment-method-icon" src="https://http2.mlstatic.com/resources/frontend/homes-korriban/assets/images/payments/mercado-pago.svg" alt="mercado pago logo"/>
                </Col>
                <Col md={10} xs={10}>
                  <h5>Paga con Mercado Pago</h5>
                  <p>Pagar con Mercado Pago es elegir cualquiera de estos medios. Es rápido, seguro y no tiene costo adicional.</p>
                </Col>
              </StyledPaymentMethodRow>
              <StyledPaymentMethodRow>
                <Col md={2} xs={2}>
                  <img className="payment-method-icon" src="https://http2.mlstatic.com/resources/frontend/homes-korriban/assets/images/payments/credit-card.svg" alt="mercado pago logo"/>
                </Col>
                <Col md={10} xs={10}>
                  <h5>Tarjeta de crédito en 3, 6 y hasta 12 meses sin intereses</h5>
                  <p>Solo para productos seleccionados</p>
                  <div>
                    <img className="payment-logo" src={visa} alt="visa logo"/>
                    <img className="payment-logo" src={mastercard} alt="mastercard logo"/>
                    <img className="payment-logo" src={amex} alt="amex logo"/>
                  </div>
                </Col>
              </StyledPaymentMethodRow>
              <StyledPaymentMethodRow>
                <Col md={2} xs={2}>
                  <img className="payment-method-icon" src="https://http2.mlstatic.com/resources/frontend/homes-korriban/assets/images/payments/debit-card.svg" alt="mercado pago logo"/>
                </Col>
                <Col md={10} xs={10}>
                  <h5>Tarjeta de débito</h5>
                  <p>Paga a un solo cargo a tu cuenta bancaria</p>
                  <div>
                    <img className="payment-logo" src={visa} alt="visa logo"/>
                    <img className="payment-logo" src={mastercard} alt="mastercard logo"/>
                  </div>
                </Col>
              </StyledPaymentMethodRow>
              <StyledPaymentMethodRow>
                <Col md={2} xs={2}>
                  <img className="payment-method-icon" src="https://http2.mlstatic.com/resources/frontend/homes-korriban/assets/images/payments/payment-agreement.svg" alt="mercado pago logo"/>
                </Col>
                <Col md={10} xs={10}>
                  <h5>Efectivo en puntos de pago, depósito y transferencia electrónica</h5>
                  <p>Es muy simple: cuando termines tu compra, te daremos las instrucciones para que sepas cómo y dónde pagarla.</p>
                  <Row>
                    <Col md={8} style={{fontSize: "12px"}}>
                      Depósito y transferencia bancaria
                      <div>
                        <img className="payment-logo logo-large" src={santander} alt="santander logo"/>
                        <img className="payment-logo logo-large" src={bbva} alt="bbva logo"/>
                        <img className="payment-logo logo-large" src={citibanamex} alt="citibanamex logo"/>
                      </div>
                    </Col>
                    <Col md={4} style={{fontSize: "12px"}}>
                      Efectivo
                      <div>
                        <img className="logo-large" src={oxxo} alt="oxxo logo"/>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </StyledPaymentMethodRow>
            </Container>
          </Modal.Body>
        </Modal>
      </>
    );
}

export default PaymentMethodsModal;
