import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Loginbar from "../../../shared/professional/Loginbar/Loginbar";
import Navigation from "../../../shared/sonoritmo/Navigation/Navigation";
import {
  getItemDetail,
  addItemToKart,
  getAdvertisementById,
  createItemQuestion,
  getItemQuestions
} from "../../../redux/actions";
import Footer from "../../../shared/footer/footer";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Markup } from "interweave";
import ProductCarousel from "../../../shared/productCarousel/ProductCarousel";
import { currencyFormat, urlFormat } from "../../../utils/format";
import { Twitter, Facebook, Whatsapp } from "react-social-sharing";
import PremiumProductModal from "../../../shared/premiumProductModal/PremiumProductModal";
import CreditPriceMonths from "../../../shared/creditPriceMonths/CreditPriceMonths";
import Loader from "../../../shared/loader/loader";
import { BASE_DOMAIN } from "../../../configuration";
import ReactGA from "react-ga";
import MetaTags from "react-meta-tags";
import styled from "styled-components";

const REACT_APP_INFORMATIVE_MODE = process.env.REACT_APP_INFORMATIVE_MODE === 'true';

class Item extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemhash: this.props.match.params.itemhash,
      selectedCategory: this.props.match.params.category
        ? this.props.match.params.category
        : "guitarras",
      questionInputValue: ""
    };

    this.clickHandler = this.clickHandler.bind(this);
    this.handleQuestionInputChange = this.handleQuestionInputChange.bind(this);
    this.handleCreateQuestionButton = this.handleCreateQuestionButton.bind(
      this
    );
    ReactGA.pageview(this.props.location.pathname);
  }

  handleQuestionInputChange(event) {
    this.setState({ ...this.state, questionInputValue: event.target.value });
  }

  componentDidMount() {
    if (this.props.type === "item") {
      this.props.getItemDetail(this.state.itemhash);
    } else {
      this.props.getAdvertisementById(this.state.itemhash);
    }
    //this.props.getItemQuestions(this.state.itemhash, this.props.type);
  }

  getShareUrl() {
    return this.props.product
      ? `${BASE_DOMAIN}/${this.props.type}/${urlFormat(
          this.props.product.model
        )}/${this.props.product.id}`
      : "";
  }

  getSocialMessage() {
    let message = !this.isPremium()
      ? `${this.props.product.model} a solo $${currencyFormat(
          this.getRealPrice()
        )}`
      : `${this.props.product.model} solo por Sonoritmo`;
    return message;
  }

  clickHandler() {
    this.props.addItemToKart("kart", { ...this.props.product, amount: 1 });
  }

  itemHasDiscount() {
    return this.props.product.price_with_discount < this.props.product.price;
  }

  getRealPrice() {
    return this.itemHasDiscount()
      ? this.props.product.price_with_discount
      : this.props.product.price;
  }

  isPremium() {
    return this.getRealPrice() < 0.1 || this.props.product.items;
  }

  getPackageItems() {
    return this.props.product.items.map(item => (
      <li>
        {item.quantity} x {item.model} {item.name}
      </li>
    ));
  }

  capitalize(s) {
    if (typeof s !== "string") return "";
    const strLower = s.toLocaleLowerCase();
    return (
      strLower
        .toLowerCase()
        .charAt(0)
        .toUpperCase() + strLower.slice(1)
    );
  }

  handleCreateQuestionButton() {
    if(!this.props.profile.id) {
      this.props.auth.login();
      return;
    }
    const questionStr = this.state.questionInputValue;
    const question = {
      id: new Date().getTime(),
      question: questionStr,
      answer: null,
      timestamp: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, 19)
        .replace("T", " "),
      user: this.props.profile,
      itemId: this.props.product.id,
      path: window.location.href,
    };
    this.props.createItemQuestion(
      this.props.product.id,
      this.props.type,
      question
    );
    this.setState({ questionInputValue: "" });
  }

  render() {
    let socialURL = this.getShareUrl();
    let photos = [];
    const ProductCarouselWrapper = styled.div`
      height: 100%;
      width: 100%;
      .responsive-image-wrapper {
        height: 49vh !important;
      }
    `;

    let itemTitle = "";
    if (this.props.product)
      itemTitle =
        (this.props.product.brand ? this.props.product.brand + " " : "") +
        (this.props.product.title ? this.props.product.title + " - " : "") +
        this.props.product.model;

    let itemDescription = this.capitalize(itemTitle);

    if (this.props.product)
      photos = this.props.product.photos
        ? this.props.product.photos
        : this.props.product.pictures.map(pic => pic.url);
    return (
      <Container fluid style={{ backgroundColor: "white" }}>
        <Row className="sonoritmo-bg-color">
          <Loginbar name="Alberto Ayala Capetillo" customer_id="442424" />
          <Navigation
            category={this.state.selectedCategory}
            auth={this.props.auth}
          />
        </Row>
        <Container>
          <Row
            className="products-content main-content"
            style={{ backgroundColor: "white" }}
          >
            {this.props.product ? (
              <>
                <Col md={6} style={{ paddingTop: "20px" }}>
                  <Row>
                    <ProductCarouselWrapper>
                      <ProductCarousel photos={photos} />
                    </ProductCarouselWrapper>
                  </Row>
                  <Row className="sold-items">
                    <p className="text-center full-width">
                      {this.props.product.sold_units
                        ? `${this.props.product.sold_units} ${
                            this.props.product.sold_units === 1
                              ? "Vendido"
                              : "Vendidos"
                          }`
                        : null}
                    </p>
                  </Row>
                  <Row
                    className="price-whitout-discount"
                    hidden={this.props.product.price < 0.1}
                  >
                    <p className="text-center full-width no-margin">
                      {this.itemHasDiscount()
                        ? `$ ${currencyFormat(this.props.product.price)}`
                        : null}
                    </p>
                  </Row>
                  <Row className="final-price" hidden={this.isPremium()}>
                    <p className="text-center full-width no-margin">
                      {`$ ${currencyFormat(this.getRealPrice())}`}
                    </p>
                  </Row>
                  <CreditPriceMonths
                    price={this.getRealPrice()}
                    hasItems={this.props.product.items}
                    hasInstallments={this.props.product.hasInstallments}
                    centered
                  />
                  <Row>
                    <p
                      className="align-middle text-center full-width no-margin"
                      hidden={this.isPremium()}
                    >
                      {!REACT_APP_INFORMATIVE_MODE &&
                      <Button variant="dark" onClick={this.clickHandler}>
                        Agregar <i className="fa fa-cart-plus clickable"></i>
                      </Button>}
                    </p>
                    <div
                      hidden={!this.isPremium()}
                      className="align-middle text-center full-width with-margin-top"
                    >
                      <PremiumProductModal model={this.props.product.model} />
                    </div>
                    <div className="align-middle text-center full-width with-margin-top">
                      <h6>Compartir en:</h6>
                      <Twitter
                        link={socialURL}
                        message={this.getSocialMessage()}
                      />
                      <Facebook link={socialURL}></Facebook>
                      <Whatsapp
                        link={socialURL}
                        message={this.getSocialMessage()}
                      />
                    </div>
                  </Row>
                </Col>
                <Col md={6} style={{ paddingTop: "20px" }}>
                  <h4>{itemTitle}</h4>
                  <br />
                  <div className="text-justify">
                    <Markup content={this.props.product.description} />
                    {this.props.product.items ? (
                      <Row>
                        <Col>
                          <h6>Este paquete incluye:</h6>
                          {this.getPackageItems()}
                        </Col>
                      </Row>
                    ) : null}
                  </div>
                </Col>
                {false&&
                <>
                  <Col md={12} style={{ marginTop: "10px" }}>
                    <h3>Preguntas y respuestas</h3>
                  </Col>
                  <Col>
                    <h6>Pregúntale a Sonoritmo</h6>
                    <Form>
                      <Form.Row>
                        <Col md={{ offset: 2, span: 7 }}>
                          <Form.Control
                            inline
                            defaultValue=""
                            value={this.state.questionInputValue}
                            type="text"
                            placeholder="Escribe una pregunta ..."
                            onChange={this.handleQuestionInputChange}
                            onKeyPress={e => {
                              if (
                                e.key === "Enter" &&
                                this.state.questionInputValue.length >= 5
                              ) {
                                e.preventDefault();
                                this.handleCreateQuestionButton(e);
                              } else if (
                                e.key === "Enter" &&
                                this.state.questionInputValue.length < 5
                              )
                                e.preventDefault();
                            }}
                          />
                        </Col>
                        <Col md={1}>
                          <Button
                            onClick={this.handleCreateQuestionButton}
                            disabled={this.state.questionInputValue.length < 5}
                          >
                            Preguntar
                          </Button>
                        </Col>
                      </Form.Row>
                    </Form>
                  </Col>
                  <Col md={{offset:1, span: 10}} style={{ marginTop: "10px" }}>
                    <h6>Últimas preguntas</h6>
                    {this.props.questions.length === 0 ? (<p>No hay preguntas, sé el primero en preguntar</p>) : null }
                    {this.props.questions.sort((a, b) =>  b.id- a.id).slice(0, 10).map(question => (
                      <>
                        <div style={{textAlign: "left", marginTop: "10px"}}>
                          <i class="fa fa-2x fa-commenting-o"></i>{" "}
                          {question.question}
                        </div>
                        {question.answer && (
                          <p style={{textAlign: "left", marginBottom: "5px"}}>
                            <i class="fa fa-2x fa-commenting-o fa-flip-horizontal"></i>
                            <span style={{ color: "#999", paddingLeft: "3px" }}>
                              {question.answer.message} - {question.answer.timestamp}
                            </span>
                          </p>
                        )}
                      </>
                    ))}
                  </Col>
                </>}
                <MetaTags>
                  <title>{itemTitle}</title>
                  <meta name="description" content={itemTitle} />
                  <meta property="og:title" content={itemDescription} />
                  <meta property="og:type" content="product" />
                  <meta property="og:description" content={itemDescription} />
                  <meta property="og:url" content={window.location.href} />
                  <meta property="og:image" content={photos[0]} />
                  <meta
                    property="product:brand"
                    content={
                      this.props.product.brand
                        ? this.props.product.brand
                        : "Sonoritmo"
                    }
                  />
                  <meta property="product:availability" content="in stock" />
                  <meta property="product:condition" content="new" />
                  <meta
                    property="product:price:amount"
                    content={!this.isPremium() ? this.getRealPrice() : 0}
                  />
                  <meta property="product:price:currency" content="MXN" />
                  <meta
                    property="product:retailer_item_id"
                    content={this.props.product.id}
                  />
                </MetaTags>
              </>
            ) : !this.props.isLoading ? (
              <h6 className="text-center w-100">404: Artículo no encontrado</h6>
            ) : (
              <div className="text-center w-100">
                <Loader />
              </div>
            )}
          </Row>
        </Container>
        <Footer />
      </Container>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    isLoading: state.global.isLoading,
    profile: state.global.profile,
    product: state.commerce.product
      ? state.commerce.product
      : state.commerce.advertisement,
    questions: state.commerce.questions
  };
};

const mapDispatchToProps = dispatch => ({
  getItemDetail: itemId => dispatch(getItemDetail(itemId)),
  getAdvertisementById: advertisementId =>
    dispatch(getAdvertisementById(advertisementId)),
  addItemToKart: (kartName, item) => dispatch(addItemToKart(kartName, item)),
  createItemQuestion: (itemId, type, question) =>
    dispatch(createItemQuestion(itemId, type, question)),
  getItemQuestions: (itemId, type) => dispatch(getItemQuestions(itemId, type))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Item);
