import { API_URL, BUSINESSBAY_API } from '../configuration';
import axios from 'axios';

function create(orderPayload) {
    const token = localStorage.getItem('access_token');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    
    return axios.post(`${API_URL}/orders/create`, orderPayload, config);
}

function createPaymentPreference(preferencePayload) {
    const token = localStorage.getItem('access_token');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    
    return axios.post(`${BUSINESSBAY_API}/payments/preference`, preferencePayload, config);
}

function updatePaymentStatus(paymentPayload) {
    const token = localStorage.getItem('access_token');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    
    return axios.post(`${BUSINESSBAY_API}/payments/update`, paymentPayload, config);
}

function getUserOrders(userId) {
    const token = localStorage.getItem('access_token');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    
    return axios.get(`${API_URL}/orders/user/${userId}`, config);
}

export const orderService = {
    create,
    createPaymentPreference,
    updatePaymentStatus,
    getUserOrders
};