import { API_URL, DEFAULT_ITEMS_BY_PAGE} from '../configuration'
import axios from 'axios';

function get(category, page, subcategory, brand, tags) {
    return axios.get(`${API_URL}/items?items=${DEFAULT_ITEMS_BY_PAGE}&category=${category}&page=${page}&subcategory=${subcategory}&brand=${brand}&tags=${tags}`);  
}

function getSubcategories(category) {
    return axios.get(`${API_URL}/items/subcategoriesbycategory?category=${category}`);  
}

function getBrandsByCategory(category) {
    return axios.get(`${API_URL}/items/brandsbycategory?category=${category}`);  
}

function getTags(category, subcategory) {
    return axios.get(`${API_URL}/items/tags?category=${category}&subcategory=${subcategory}`);  
}

function getItemDetail(itemId) {
    return axios.get(`${API_URL}/items/info/${itemId}`);  
}

function search(text) {
    return axios.get(`${API_URL}/items/search?text=${text}`);  
}

function getFeatured() {
    return axios.get(`${API_URL}/items/featured`);  
}

export const productService = {
    get,
    getSubcategories,
    getBrandsByCategory,
    getTags,
    getItemDetail,
    search,
    getFeatured,
};
