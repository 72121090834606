import React, { Component } from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Loginbar from '../../../shared/professional/Loginbar/Loginbar';
import Navigation from '../../../shared/sonoritmo/Navigation/Navigation';
import ProductList from '../../../shared/productList/ProductList';
import { getAdvertisements } from '../../../redux/actions';
import Loader from '../../../shared/loader/loader';
import Footer from '../../../shared/footer/footer';
import ReactGA from 'react-ga';

class Advertisement extends Component {

  constructor(props) {
    super(props);
    this.props = props;
  
    this.state = {
      isLoading: false,
      products: [],
      selectedCategory: 'packages/bose-instalacion',
    }
  }

  componentDidMount() {
    this.props.getAdvertisements();
  }

  mapAdvertisementsToProducts(advertisements){
    return advertisements.map(item => {return {...item, photos: item.pictures.map(pic => pic.url) }})
  }

  render() {
    const category = 'packages/bose-instalacion';
    const products = this.mapAdvertisementsToProducts(this.props.advertisements);
    const page = 1;
    const pages = 1;
    const currentPage = 1;
    const nextPage = 1;
    ReactGA.pageview(this.props.location.pathname);

    return (
        <Container fluid>
            <Row className="sonoritmo-bg-color">
              <Loginbar name="Alberto Ayala Capetillo" customer_id="442424"/>
              <Navigation category={this.state.selectedCategory} auth={this.props.auth}/>
            </Row>
            <Row className="h-100 products-content">
              {!this.state.isLoading ? 
                (<ProductList category={category} products={products} page={page} pages={pages} currentPage={currentPage} nextPage={nextPage} paginationBgColor="#008A9D" paginationFontColor="black"/>):
                (<Loader/>)
              }
            </Row>
            <Footer/> 
        </Container>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    isLoading: state.global.isLoading,
    advertisements: state.commerce.advertisements
  }
}
  
const mapDispatchToProps = dispatch => ({
  getAdvertisements: () => dispatch(getAdvertisements()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Advertisement);
