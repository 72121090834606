import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Chat } from 'bbcomponents';
import { getGeneralChatMessages, sendGeneralMessage, getProfile, toogleChat } from '../../redux/actions';

class LiveChat extends Component {
    
    constructor(props) {
        super(props);
        this.props = props;
        this.newMessageHandler = this.newMessageHandler.bind(this);
        this.toogleChatHandler = this.toogleChatHandler.bind(this);
    }

    componentDidMount(){
      if(this.props.listener) this.props.listener();
      this.props.getProfile();
      if(this.props.profile.id) this.props.getGeneralChatMessages(this.props.profile.id);
    }

    componentDidUpdate(){
      if(!this.props.listener && this.props.profile.id) this.props.getGeneralChatMessages(this.props.profile.id);
    }

    newMessageHandler(message){
      this.props.sendGeneralMessage(this.props.profile.id, message);
    }

    toogleChatHandler(){
      this.props.toogleChat();
    }

    render() {
        let chatProps = {
            title: "Sonoritmo",
            buttonPlaceholder: "¿Buscabas algo o tienes alguna duda?, inicia un chat",
            agentProfilePicUrl: "https://sonoritmobbpro.s3-us-west-2.amazonaws.com/images/sonoritmo.jpeg",
            customerProfilePicUrl: this.props.profile.picture,
            displayUserName: this.props.profile.first_name,
            mainColor: "#282c34",
            headerTextColor: "white",
            metadata: {
              section: window.location.pathname,
              profilePicture: this.props.profile.picture,
            },
            newMessageHandler: this.newMessageHandler, // This function will be triggered after a new message is submited
            customerIsAuthenticated: this.props.auth.isAuthenticated() && this.props.profile.id, // If you need to perform a login in your app before activating the chat it should be false
            handleUserAuthentication: () => this.props.auth.login() // If customerIsAuthenticated = false it will be triggered when user press the circle chat icon
        };

        return (
            <Chat {...chatProps} messages={this.props.messages} isShownChatbox={this.props.chatOpen} toogleChatHandler={this.toogleChatHandler}/>
        );
    }
}

const mapStateToProps = function(state) {
    return {
      profile: state.global.profile,
      messages: state.global.messages,
      listener: state.global.chatListener,
      chatOpen: state.global.chatOpen
    }
}
    
const mapDispatchToProps = dispatch => ({
  getProfile: () => dispatch(getProfile()),
  toogleChat: () => dispatch(toogleChat()),
  sendGeneralMessage: (userId, message) => dispatch(sendGeneralMessage(userId, message)),
  getGeneralChatMessages: (userId) => dispatch(getGeneralChatMessages(userId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveChat);
