const currencyFormat = (value) => (value*1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

const urlFormat = (str) => str.toLowerCase().replace(/[^a-zA-Z0-9-_]/g, '-');

const dateToHumanFormat = (strDate, separator = "at") => {
    const year = strDate.slice(0, 4);
    const month = strDate.slice(5, 7);
    const day = strDate.slice(8, 10);
    const time = strDate.slice(11, 19);
    const formatedDate = `${day}/${month}/${year} ${separator} ${time}`

    return formatedDate;
}

const mapStoreCodeToAddress = (storeCode) => {
    switch (storeCode) {
        case "balbuena":
            return "Retorno 11 Calzada Ignacio Zaragoza #47 local B, Jardin Balbuena, Venustiano Carranza, CDMX";
        default:
            return "";
    }
}

const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
  
module.exports = {
    currencyFormat,
    urlFormat,
    dateToHumanFormat,
    mapStoreCodeToAddress,
    capitalize,
}