import { BUSINESSBAY_API, API_URL, COMPANY_ID } from '../configuration';
import axios from 'axios';

function getRewardsBalance(userId) {
    const token = localStorage.getItem('access_token');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    
    return axios.get(`${BUSINESSBAY_API}/rewards?user_id=${userId}&company_id=${COMPANY_ID}`, config);
}

function getRewardsConfigurations() {
    const token = localStorage.getItem('access_token');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    
    return axios.get(`${BUSINESSBAY_API}/rewards/configurations?company_id=${COMPANY_ID}`, config);
}

function exchangeRewards(userId, ticketCode, points) {
    const payload = {
        user_id: userId,
        code: ticketCode,
        company_id: COMPANY_ID,
        points
    };
    const token = localStorage.getItem('access_token');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    
    return axios.post(`${API_URL}/rewards/exchange`, payload, config);
}

export const rewardsService = {
    getRewardsBalance,
    exchangeRewards,
    getRewardsConfigurations
};