import React from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { openChatAndSendMessage } from '../../redux/actions';
import Auth from '../../auth/auth';

const PremiumProduct = (props) => {
    const auth = new Auth()
    const message = `Hola! estoy interesado en tu producto modelo ${props.model}`;
    const messagePremium = {
        id: new Date().getTime(),
        name: props.profile.first_name,
        timestamp: new Date(Date.now()-(new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 19).replace('T', ' '),
        type: "customer",
        message,
        metadata: {
            section: window.location.pathname,
            profilePicture: props.profile.picture,
            model: props.model
        },
    }
    
    return (
        <>
            <p className="align-middle text-center full-width no-margin">
                Premium
            </p>
            <p className="align-middle text-center full-width no-margin">
                <Button size="sm" onClick={_ => auth.isAuthenticated() ? props.openChatAndSendMessage(props.profile.id, messagePremium) : auth.login()}>Contactar</Button>
            </p>
        </>
    );
}

const mapStateToProps = function(state) {
    return {
      profile: state.global.profile,
      messages: state.global.messages,
      listener: state.global.chatListener,
      chatOpen: state.global.chatOpen
    }
}
    
const mapDispatchToProps = dispatch => ({
  openChatAndSendMessage: (userId, message) => dispatch(openChatAndSendMessage(userId, message)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PremiumProduct);
