import React, { Component } from 'react';
import { connect } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../../bitstruments_logo.png';
import styled from 'styled-components';
import { STORE_CATEGORIES } from '../../../configuration';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { getProfile, getSubcategories, exchangeRewards } from '../../../redux/actions';
import Container from 'react-bootstrap/Container';
import RewardsExchangeModal from '../../../shared/rewardsExchangeModal/RewardsExchangeModal';

const REACT_APP_INFORMATIVE_MODE = process.env.REACT_APP_INFORMATIVE_MODE === 'true';

class Navigation extends Component {
  
  constructor(props) {
    super(props);

    this.props = props;

    this.state = {
      selectedCategory: this.props.category ? this.props.category : 'all',
    }

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.getNavBarItems = this.getNavBarItems.bind(this);
    this.getSubMenuItems = this.getSubMenuItems.bind(this);
  }

  componentDidMount() {
    STORE_CATEGORIES.map(item =>this.props.getSubcategories(item));
  }

  handleSearch(e) {
    if (e.key === 'Enter') {
      const searchStr = e.target.value;
      let path = `/search?text=${searchStr}`;
      window.location = path;
    }
  }

  isSelectedBrand(brand){
    const { selectedBrand } = this.state;
    return brand === selectedBrand;
  }

  getSelectedCategory(){
    return this.state.selectedCategory;
  }

  getCapitalizedSelectedBrand(){
    const brand = this.state.selectedBrand;
    return brand.charAt(0).toUpperCase() + brand.slice(1);
  }

  getStyledNavItem() {
    return styled(Nav.Item)`
      & {
        color: white !important;
      }
      
      & :hover {
        color: white !important;
        background-color: #455a64;
      }
      
      & .active {
        color: white !important;
        background-color: #455a64 !important;
      }

      .dropdown-menu {
        background-color: white;
        color: black;
      }
      
      .dropdown-item {
        background-color: white;
        color: black;
      }
      
      .dropdown-item:hover{
        background-color: white;
        color: black !important;
      }`;
  }

  getSubMenuItems(category){
    if(this.props.subcategoryGroups[`${category}`])
    return this.props.subcategoryGroups[`${category}`].sort((a, b) => a.subcategory.localeCompare(b.subcategory)).map(subcategory => (
      <NavDropdown.Item key={`/${category}?subcategory=${subcategory.subcategory}`} href={`/${category}?subcategory=${subcategory.subcategory}`.toLowerCase()}>
        {subcategory.subcategory}
      { subcategory.picture && <img alt={subcategory.subcategory} style={{ maxHeight: "80px" }} src={ subcategory.picture }/> }
      </NavDropdown.Item>
    )) 
    else return [];
  }
  
  getNavBarItems(){
    return STORE_CATEGORIES.map(
      item => {
        const submenus = this.getSubMenuItems(item);
        return (
          <NavDropdown style={{fontSize: "12px"}} key={item} title={item} id="basic-nav-dropdown">
            {submenus}
          </NavDropdown>
        )
      }
    )
  }

  login(){
    this.props.auth.login();
    this.props.getProfile();
  }

  logout() {
    this.props.auth.logout();
    window.location.reload();
  }

  isAuthenticated(){
    return this.props.auth.isAuthenticated();
  }

  getUserMenuTitle(){
    return !this.isAuthenticated() ?
      (<i className="fa fa-user fa-2x white-text"></i>) :
      (<>{this.props.profile.first_name}</>)
  }

  getMobileNav(){
    const StyledNavItem = this.getStyledNavItem();
    const navBarItems = this.getNavBarItems();
    const selectedCategory = this.getSelectedCategory();
    
    return (
      <>
        <Container className="d-xs-block d-md-none">
          <Navbar expand="lg" as="div">
              <Navbar.Brand className="navbar-brand-sonoritmo" href="/">
                  <img
                      src={logo}
                      className="d-inline-block align-top logo-sonoritmo"
                      alt="Sonoritmo logo"
                  />
              </Navbar.Brand>
              <Nav defaultActiveKey={`/${selectedCategory}`} navbar={false} className="d-xs-block d-md-none mobile-nav">
                <StyledNavItem className="disable-hover clickable">
                  {!REACT_APP_INFORMATIVE_MODE && <Nav.Link href="/kart">
                    <div className="kart">
                      <i className="fa fa-2x fa-shopping-cart fa white-text" ></i>
                      { this.props.kart.length ? <span className='badge badge-warning' id='lblCartCount'> {this.props.kart.reduce((prev,next) => prev + next.amount, 0)} </span> : null}
                    </div>
                  </Nav.Link>
                  }
                </StyledNavItem>
              </Nav>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="full-width display-block" defaultActiveKey={`/${selectedCategory}`}>
                      { navBarItems }
                      <StyledNavItem className="nav-item-pad border-left">
                          <Nav.Link  style={{fontSize: "12px"}}  href="/sucursales">Sucursales</Nav.Link>
                      </StyledNavItem>
                      <StyledNavItem className="disable-hover clickable d-none d-md-block pull-right">
                        {!REACT_APP_INFORMATIVE_MODE && <Nav.Link href="/kart">
                          <div className="kart">
                            <i className="fa fa-shopping-cart fa-2x white-text" ></i>
                            { this.props.kart.length ? <span className='badge badge-warning' id='lblCartCount'> {this.props.kart.reduce((prev,next) => prev + next.amount, 0)} </span> : null}
                          </div>
                        </Nav.Link>}
                      </StyledNavItem>
                      {false &&
                      <StyledNavItem>
                        <NavDropdown id="basic-nav-dropdown" title={this.getUserMenuTitle()}>
                          {!this.isAuthenticated() ?
                              (<NavDropdown.Item onClick={this.login}>Iniciar Sesión</NavDropdown.Item>) :
                              (
                              <>
                                <NavDropdown.Item href="/ordenes">
                                  <i className="fa fa-shopping-bag" style={{paddingRight: "3px"}}></i>
                                  Mis compras
                                </NavDropdown.Item>
                                {false &&
                                <RewardsExchangeModal submitHandler={this.props.exchangeRewards} userProfile={this.props.profile}>
                                  <NavDropdown.Item href="#">
                                    <i className="fa fa-ticket" style={{paddingRight: "3px"}}></i>
                                    Canjear Sonopuntos
                                  </NavDropdown.Item>
                                </RewardsExchangeModal>
                                }
                                <NavDropdown.Item onClick={this.logout}>Cerrar Sesión</NavDropdown.Item>
                              </>
                              )
                          }
                        </NavDropdown>
                      </StyledNavItem>}
                  </Nav>
              </Navbar.Collapse>
          </Navbar>
        </Container>
        <Container className="d-xs-block d-md-none">
          <Navbar>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="full-width" defaultActiveKey={`/${selectedCategory}`} fill>
                    <StyledNavItem className="disable-hover clickable search-item-mobile pull-left">
                      <input type="text" id="search-input" placeholder="Buscar..." onKeyDown={this.handleSearch}/>
                    </StyledNavItem>
                  </Nav>
              </Navbar.Collapse>
          </Navbar>
        </Container>
      </>
    )
  }

  getDesktopNav(){
    const StyledNavItem = this.getStyledNavItem();
    const navBarItems = this.getNavBarItems();
    const selectedCategory = this.getSelectedCategory();
    return (
      <>
        <Container className="d-none d-md-block">
          <Navbar expand="lg" as="div">
              <Navbar.Brand className="navbar-brand-sonoritmo" href="/">
                  <img
                      src={logo}
                      className="d-inline-block align-top logo-sonoritmo"
                      alt="Sonoritmo logo"
                  />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="full-width display-block" defaultActiveKey={`/${selectedCategory}`}>
                      <StyledNavItem className="disable-hover clickable search-item pull-left">
                        <input type="text" id="search-input" placeholder="Buscar..." onKeyDown={this.handleSearch}/>
                      </StyledNavItem>
                      <StyledNavItem className="disable-hover clickable d-none d-md-block pull-right">
                        {!REACT_APP_INFORMATIVE_MODE &&
                        <Nav.Link href="/kart">
                          <div className="kart">
                            <i className="fa fa-shopping-cart fa-2x white-text" ></i>
                            { this.props.kart.length ? <span className='badge badge-warning' id='lblCartCount'> {this.props.kart.reduce((prev,next) => prev + next.amount, 0)} </span> : null}
                          </div>
                        </Nav.Link>}
                      </StyledNavItem>
                      {!REACT_APP_INFORMATIVE_MODE &&
                      <StyledNavItem className="pull-right">
                        <NavDropdown id="basic-nav-dropdown" title={this.getUserMenuTitle()}>
                          {!this.isAuthenticated() ?
                            (<NavDropdown.Item onClick={this.login}>Iniciar Sesión</NavDropdown.Item>) :
                            (
                            <>
                              <NavDropdown.Item href="/ordenes">
                                <i className="fa fa-shopping-bag" style={{paddingRight: "3px"}}></i>
                                Mis compras
                              </NavDropdown.Item>
                              {false && <RewardsExchangeModal submitHandler={this.props.exchangeRewards} userProfile={this.props.profile}>
                                <NavDropdown.Item href="#">
                                  <i className="fa fa-ticket" style={{paddingRight: "3px"}}></i>
                                  Canjear Sonopuntos
                                </NavDropdown.Item>
                              </RewardsExchangeModal>}
                              <NavDropdown.Item onClick={this.logout}>Cerrar Sesión</NavDropdown.Item>
                            </>
                            )
                          }
                        </NavDropdown>
                      </StyledNavItem>}
                  </Nav>
              </Navbar.Collapse>
          </Navbar>
        </Container>
        <Container className="d-none d-md-block">
          <Navbar>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="full-width" defaultActiveKey={`/${selectedCategory}`} fill>
                      { navBarItems }
                      <StyledNavItem className="nav-item-pad border-left">
                          <Nav.Link  style={{fontSize: "12px"}}  href="/sucursales">Sucursales</Nav.Link>
                      </StyledNavItem>
                  </Nav>
              </Navbar.Collapse>
          </Navbar>
        </Container>
      </>
    )
  }
  
  render() {
    const DesktopNav = this.getDesktopNav()
    const MobileNav = this.getMobileNav();
    return (
      <>
       {DesktopNav}
       {MobileNav}
      </>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    kart: state.global.kart,
    profile: state.global.profile,
    subcategories: state.commerce.subcategories,
    subcategoryGroups: state.commerce.subcategoryGroups,
    rewards: state.commerce.rewards,
  }
}

const mapDispatchToProps = dispatch => ({
  getProfile: () => dispatch(getProfile()),
  getSubcategories: (category) => dispatch(getSubcategories(category, true)),
  exchangeRewards: (userId, code, points) => dispatch(exchangeRewards(userId, code, points))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigation);
