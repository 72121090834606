import React from 'react';
import { Col } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';
import ProductCard from '../productCard/ProductCard';


const events = {
    onDragged: (event) => console.log(event),
    onChanged: (event) => console.log(event),
};

const ProductOwlCarousel = ({products, addToKartHandler, smItems = 1, mdItems = 2, lgItems = 3}) => {
    const options = {
        nav: true,
        rewind: true,
        autoplay: false,
        responsive: {
            0: {
                items: smItems
            },
            600: {
                items: mdItems
            },
            1090: {
                items: lgItems
            }
        }
    };
    
    return (
        <>
        { products.length > 0 && 
        <OwlCarousel options={options} events={events} >
            {products.map(product => (
                <div key={product.id}>
                    <Col className="with-margin-bottom with-margin-top">
                        <ProductCard
                            product={product}
                            key={product.id}
                            photos={product.photos}
                            model={product.model}
                            price={product.price}
                            price_with_discount={product.price_with_discount}
                            stock={product.stock}
                            description={product.description}
                            addToKartHandler={addToKartHandler}
                        />
                    </Col>
                </div>
            ))}
        </OwlCarousel> }
        </>
    );
}

export default ProductOwlCarousel;