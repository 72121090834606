import 'react-owl-carousel2/src/owl.carousel.css';

import React, { Component } from 'react';

import {
  Button,
  Nav,
} from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ReactGA from 'react-ga';
import OwlCarousel from 'react-owl-carousel2';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Footer from '../../../shared/footer/footer';
import PaymentMethodsModal
  from '../../../shared/paymentMethodsModal/PaymentMethodsModal';
import Loginbar from '../../../shared/professional/Loginbar/Loginbar';
import RewardsModal from '../../../shared/rewardsModal/RewardsModal';
import SecurityModal from '../../../shared/securityModal/SecurityModal';
import Navigation from '../../../shared/sonoritmo/Navigation/Navigation';
import FeaturedProducts from './featuredProducts/FeaturedProducts';
import { cmsService } from '../../../services/cms.service';

const PaymentMethodBanner = styled(Row)`
  margin: auto;
  padding: 0px 10px 0px 10px;
  width: 80vw;
  background-color: white;
  min-height: 90px;
  margin-top: 10px;

  .align-vertical {
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 10px;
  }
`;

const SecurityCard = styled(Row)`
  background-color: white;
  margin: 43px 0px 20px 0px;
  min-height: 280px;
  .align-vertical {
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 10px;
  }
`;

const SplitBannerContainer = styled(Row)`
  margin: auto;
  padding: 0;
  width: 80vw;
  background-color: #ebebec;
  min-height: 90px;
  margin-top: 10px;

  .align-vertical {
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 10px;
  }

  .owl-carousel .owl-item img {
    display: block !important;
    width: 100% !important;
    max-height: 366px;
  }
`;

const SplitBanner = styled(Col)`
  height: 133px;
  margin: 0;
  padding: 0;

  & > img {
    height: 100%;
    width: 100%;
  }
`;

const SplitBannerLarge = styled(Col)`
  max-height: 366px;
  margin: 0;
  padding-left: 0px;
  padding-right: 0px;

  & > img {
    height: 100%;
    width: 100%;
  }
`;

const SplitBannerLargeHalf = styled(Col)`
  height: 178px;
  margin: 0;
  padding-left: 15px;
  padding-right: 0px;
  padding-bottom: 0px;
  margin-bottom: 10px;

  & > img {
    height: 100%;
    width: 100%;
  }
`;

const LinkToProduct = styled(Nav.Link)`
  padding: 0;
  margin: 0;
`;

const options = {
  nav: false,
  rewind: true,
  autoplay: true,
  responsive: {
    0: {
      items: 1
    },
    600: {
      items: 1
    },
    1090: {
      items: 1
    }
  }
};

const optionCarouselLarge = {
  nav: false,
  rewind: true,
  autoplay: true,
  responsive: {
    0: {
      items: 1
    },
    600: {
      items: 1
    },
    1090: {
      items: 1
    }
  }
};

const images = [
  {
    alt: "somos distribuidores autorizados paiste",
    src: "https://i.postimg.cc/mkSSRch0/paistebannermobil.png",
    href: "#"
  },
  {
    alt: "paiste",
    src: "https://i.postimg.cc/jjcdJnr2/paistesoundcenter.png",
    href: "#"
  },
  {
    alt: "pst5 series",
    src: "https://i.postimg.cc/brJTjnqt/pst5banner.png",
    href: "/item/068es14---new-pst-5-essential-set-14-18/1024"
  },
  {
    alt: "paiste heavy rock banner",
    src: "https://i.postimg.cc/rF5jWxfq/paisteheavyrockbanner.png",
    href: "/item/170rset---pst-7-heavy-rock-set-14-16-20/1042"
  },
  {
    alt: "paiste color soundminibanner",
    src: "https://i.postimg.cc/G3WzHqJM/paistecolorsoundminibanner.png",
    href: "/item/1921622----900-cs-red-ride-22-pulgadas-/10973"
  },
  {
    alt: "PST8",
    src: "https://i.postimg.cc/W3K8mnR1/PST8minibanner.png",
    href: "/item/1803114---pst-8-reflector-sound-edge-hi-hat-14-pulgadas-/1076"
  }
];

const CmsUrl = process.env.REACT_APP_CSM_URL;

const PaisteSection = (
    <>
    <Container fluid>
      <div className="d-lg-none d-md-block">
        <SplitBannerContainer>
          <OwlCarousel options={options}>
            <SplitBanner lg={6} md={12}>
              <img
                src={images[0].src}
                alt={images[0].alt}
              />
            </SplitBanner>
          </OwlCarousel>
        </SplitBannerContainer>
      </div>
      <div className="d-none d-lg-block">
        <SplitBannerContainer>
          <SplitBannerLarge lg={12} md={12} className="clickable">
            <img
              src={images[1].src}
              alt={images[1].alt}
            />
          </SplitBannerLarge>
        </SplitBannerContainer>
        <SplitBannerContainer>
          <SplitBannerLarge lg={8} md={12} className="clickable">
            <OwlCarousel options={optionCarouselLarge}>
              <LinkToProduct href={images[2].href}>
                <div>
                  <img
                    src={images[2].src}
                    alt={images[2].alt}
                  />
                </div>
              </LinkToProduct>
              <LinkToProduct href={images[3].href}>
                <div>
                  <img
                    src={images[3].src}
                    alt={images[3].alt}
                  />
                </div>
              </LinkToProduct>
            </OwlCarousel>
          </SplitBannerLarge>
          <SplitBannerLarge lg={4} className="clickable">
            <LinkToProduct href={images[4].href}>
              <SplitBannerLargeHalf lg={12} className="clickable">
                <img
                  src={images[4].src}
                  alt={images[4].alt}
                />
              </SplitBannerLargeHalf>
            </LinkToProduct>
            <OwlCarousel
              options={{ ...optionCarouselLarge, autoplayTimeout: 3000 }}
            >
              <LinkToProduct href={images[5].href}>
                <SplitBannerLargeHalf lg={12} className="clickable">
                  <img
                    src={images[5].src}
                    alt={images[5].alt}
                  />
                </SplitBannerLargeHalf>
              </LinkToProduct>
            </OwlCarousel>
          </SplitBannerLarge>
        </SplitBannerContainer>
      </div>
      <div className="d-lg-none d-md-block">
        <SplitBannerContainer>
          <SplitBannerLarge md={12} className="clickable">
            <OwlCarousel
              options={{ ...optionCarouselLarge, autoplayTimeout: 3000 }}
            >
              <LinkToProduct href={images[2].href}>
                <div>
                  <img
                    src={images[2].src}
                    alt={images[2].alt}
                  />
                </div>
              </LinkToProduct>
              <LinkToProduct href={images[3].href}>
                <div>
                  <img
                    src={images[3].src}
                    alt={images[3].alt}
                  />
                </div>
              </LinkToProduct>
            </OwlCarousel>
          </SplitBannerLarge>
        </SplitBannerContainer>
      </div>
      <div className="d-lg-none d-md-block">
        <SplitBannerContainer>
          <SplitBannerLarge md={12} className="clickable">
            <OwlCarousel options={{ ...optionCarouselLarge, nav: true }}>
              <LinkToProduct href={images[4].href}>
                <div>
                  <img
                    src={images[4].src}
                    alt={images[4].alt}
                  />
                </div>
              </LinkToProduct>
              <LinkToProduct href={images[5].href}>
                <div>
                  <img
                    src={images[5].src}
                    alt={images[5].alt}
                  />
                </div>
              </LinkToProduct>
            </OwlCarousel>
          </SplitBannerLarge>
        </SplitBannerContainer>
      </div>
    </Container>
    <Container fluid>
      <Row>
        <Col lg={{ offset: 1, span: 10 }}>
          <FeaturedProducts
            brand="PAISTE"
            smItems={1}
            mdItems={3}
            lgItems={4}
          />
        </Col>
      </Row>
    </Container>
    </>
);

const SteinbergSection = (
  <>
  <Container fluid>
    <div className="d-lg-none d-md-block">
      <SplitBannerContainer>
        <OwlCarousel options={options}>
          <SplitBanner lg={6} md={12}>
            <img
              src="https://sonoritmobbpro.s3-us-west-2.amazonaws.com/images/landing_sono/steinbergdistmobil.jpg"
              alt="somos distribuidores autorizados steinberg"
            />
          </SplitBanner>
        </OwlCarousel>
      </SplitBannerContainer>
    </div>
    <div className="d-none d-lg-block">
      <SplitBannerContainer>
        <SplitBannerLarge lg={12} md={12} className="clickable">
          <img
            src="https://sonoritmobbpro.s3-us-west-2.amazonaws.com/images/landing_sono/steinbergbanerweb.jpg"
            alt="steinberg"
          />
        </SplitBannerLarge>
      </SplitBannerContainer>
      <SplitBannerContainer>
        <SplitBannerLarge lg={8} md={12} className="clickable">
          <OwlCarousel options={optionCarouselLarge}>
            <LinkToProduct href="/item/ur44c----interfaz-usb-3-tipo-c-6x4-ch-i-o-midi-dsp-/11053">
              <div>
                <img
                  src="https://sonoritmobbpro.s3-us-west-2.amazonaws.com/images/landing_sono/steinberurcbig.jpg"
                  alt="ur44c series"
                />
              </div>
            </LinkToProduct>
            <LinkToProduct href="/item/cubasepro10---cubasepro10/10843">
              <div>
                <img
                  src="https://sonoritmobbpro.s3-us-west-2.amazonaws.com/images/landing_sono/cubase.jpg"
                  alt="cubase pro 10"
                />
              </div>
            </LinkToProduct>
          </OwlCarousel>
        </SplitBannerLarge>
        <SplitBannerLarge lg={4} className="clickable">
          <LinkToProduct href="/item/ur22crpack----paquete-de-interfaz-con-aud-fonos-y-micr-fono-/11051">
            <SplitBannerLargeHalf lg={12} className="clickable">
              <img
                src="https://sonoritmobbpro.s3-us-west-2.amazonaws.com/images/landing_sono/recordingpacksmall.jpg"
                alt="PAQUETE DE INTERFAZ CON AUDíFONOS Y MICRóFONO"
              />
            </SplitBannerLargeHalf>
          </LinkToProduct>
          <OwlCarousel
            options={{ ...optionCarouselLarge, autoplayTimeout: 3000 }}
          >
            <LinkToProduct href="/search?text=rupert">
              <SplitBannerLargeHalf lg={12} className="clickable">
                <img
                  src="https://sonoritmobbpro.s3-us-west-2.amazonaws.com/images/landing_sono/rupertsmall.jpg"
                  alt="rupert series"
                />
              </SplitBannerLargeHalf>
            </LinkToProduct>
          </OwlCarousel>
        </SplitBannerLarge>
      </SplitBannerContainer>
    </div>
    <div className="d-lg-none d-md-block">
      <SplitBannerContainer>
        <SplitBannerLarge md={12} className="clickable">
          <OwlCarousel
            options={{ ...optionCarouselLarge, autoplayTimeout: 3000 }}
          >
            <LinkToProduct href="/item/ur44c----interfaz-usb-3-tipo-c-6x4-ch-i-o-midi-dsp-/11053">
              <div>
                <img
                  src="https://sonoritmobbpro.s3-us-west-2.amazonaws.com/images/landing_sono/steinberurcbig.jpg"
                  alt="ur44c series"
                />
              </div>
            </LinkToProduct>
            <LinkToProduct href="/item/cubasepro10---cubasepro10/10843">
              <div>
                <img
                  src="https://sonoritmobbpro.s3-us-west-2.amazonaws.com/images/landing_sono/cubase.jpg"
                  alt="cubase pro 10"
                />
              </div>
            </LinkToProduct>
          </OwlCarousel>
        </SplitBannerLarge>
      </SplitBannerContainer>
    </div>
    <div className="d-lg-none d-md-block">
      <SplitBannerContainer>
        <SplitBannerLarge md={12} className="clickable">
          <OwlCarousel options={{ ...optionCarouselLarge, nav: true }}>
            <LinkToProduct href="/item/ur22crpack----paquete-de-interfaz-con-aud-fonos-y-micr-fono-/11051">
              <div>
                <img
                  src="https://sonoritmobbpro.s3-us-west-2.amazonaws.com/images/landing_sono/recordingpacksmall.jpg"
                  alt="PAQUETE DE INTERFAZ CON AUDíFONOS Y MICRóFONO"
                />
              </div>
            </LinkToProduct>
            <LinkToProduct href="/search?text=rupert">
              <div>
                <img
                  src="https://sonoritmobbpro.s3-us-west-2.amazonaws.com/images/landing_sono/rupertsmall.jpg"
                  alt="rupert series"
                />
              </div>
            </LinkToProduct>
          </OwlCarousel>
        </SplitBannerLarge>
      </SplitBannerContainer>
    </div>
  </Container>
  <Container fluid>
    <Row>
      <Col lg={{ offset: 1, span: 10 }}>
        <FeaturedProducts
          brand="STEINBERG"
          smItems={1}
          mdItems={3}
          lgItems={4}
        />
      </Col>
    </Row>
  </Container>
  </>
);

const SonopuntosImg = () => (
  <img src="https://sonoritmobbpro.s3-us-west-2.amazonaws.com/images/landing_sono/sonopuntos.webp" alt="Tus compras generan sonopuntos"/>
)

const BoseL1V8 = () => (
  <LinkToProduct href="/item/l1-pro8---sistema-pa-activo-portable-con-subwoofer-y-bluetooth/11324">
    <div>
      <img src="https://sonoritmobbpro.s3-us-west-2.amazonaws.com/images/landing_sono/L1Pro8_Banner-01.webp" alt="proximamente l1 pro 8"/>
    </div>
  </LinkToProduct>
)
const BoseL1V8Prices = () => (
  <LinkToProduct href="/item/l1-pro16---sistema-pa-activo-portable-con-subwoofer-y-bluetooth/11325">
    <div>
      <img src="https://sonoritmobbpro.s3-us-west-2.amazonaws.com/images/landing_sono/L1Pro16-32_Banner-02.webp" alt="l1 pro 8 disponible a partir de diciembre"/>
    </div>
  </LinkToProduct>
)

const BoseS1ProFR = () => (
  <LinkToProduct href="/item/s1-pro-fr---s1-pro-factory-renewed/11166">
    <div>
      <img src="https://sonoritmobbpro.s3-us-west-2.amazonaws.com/images/landing_sono/s1probanner2FR.webp" alt="bose s1 pro factory renewed"/>
    </div>
  </LinkToProduct>
)

const BoseSection = (
  <>
    <Container fluid>
      <div className="d-none d-lg-block">
        <SplitBannerContainer>
          <SplitBanner lg={6} md={12} className="clickable">
            <img
              src="https://i.postimg.cc/C58XDDJW/boselogodealer.jpg"
              alt="somos distribuidores autorizados bose"
            />
          </SplitBanner>
          <RewardsModal>
            <SplitBanner lg={6} md={12} className="clickable">
              <SonopuntosImg/>
            </SplitBanner>
          </RewardsModal>
        </SplitBannerContainer>
      </div>
      <div className="d-lg-none d-md-block">
        <SplitBannerContainer>
          <OwlCarousel options={options}>
            <SplitBanner lg={6} md={12}>
              <img
                src="https://i.postimg.cc/C58XDDJW/boselogodealer.jpg"
                alt="somos distribuidores autorizados bose"
              />
            </SplitBanner>
            <RewardsModal>
              <SplitBanner lg={6} md={12} className="clickable">
                <SonopuntosImg/>
              </SplitBanner>
            </RewardsModal>
          </OwlCarousel>
        </SplitBannerContainer>
      </div>
    </Container>
    <Container fluid>
      <div className="d-none d-lg-block">
        <SplitBannerContainer>
          <SplitBannerLarge lg={8} md={12} className="clickable">
            <OwlCarousel options={optionCarouselLarge}>
              <BoseL1V8/>
              <BoseL1V8Prices/>
              <BoseS1ProFR/>
            </OwlCarousel>
          </SplitBannerLarge>
          <SplitBannerLarge lg={4} className="clickable">
            <LinkToProduct href="/item/f1model812---altavoz-de-matriz-flexible-f1-modelo-812/8307">
              <SplitBannerLargeHalf lg={12} className="clickable">
                <img
                  src="https://i.postimg.cc/SsW4cwZS/f1bannerpromo.jpg"
                  alt="bose f1 en promocion"
                />
              </SplitBannerLargeHalf>
            </LinkToProduct>
            <OwlCarousel
              options={{ ...optionCarouselLarge, autoplayTimeout: 3000 }}
            >
              <LinkToProduct href="/item/s1pro-backpack---backpack-para-bose-s1-pro---809781-0010---/10590">
                <SplitBannerLargeHalf lg={12} className="clickable">
                  <img
                    src="https://i.postimg.cc/tTfYY3ZF/accesoriesbag.jpg"
                    alt="backpack s1"
                  />
                </SplitBannerLargeHalf>
              </LinkToProduct>
            </OwlCarousel>
          </SplitBannerLarge>
        </SplitBannerContainer>
      </div>
      <div className="d-lg-none d-md-block">
        <SplitBannerContainer>
          <SplitBannerLarge md={12} className="clickable">
            <OwlCarousel options={{ ...optionCarouselLarge, nav: true }}>
              <BoseL1V8/>
              <BoseL1V8Prices/>
              <BoseS1ProFR/>
            </OwlCarousel>
          </SplitBannerLarge>
        </SplitBannerContainer>
      </div>
      <div className="d-lg-none d-md-block">
        <SplitBannerContainer>
          <SplitBannerLarge md={12} className="clickable">
            <OwlCarousel
              options={{ ...optionCarouselLarge, autoplayTimeout: 3000 }}
            >
              <LinkToProduct href="/item/s1pro-backpack---backpack-para-bose-s1-pro---809781-0010---/10590">
                <img
                  src="https://i.postimg.cc/tTfYY3ZF/accesoriesbag.jpg"
                  alt="backpack s1"
                />
              </LinkToProduct>
            </OwlCarousel>
          </SplitBannerLarge>
        </SplitBannerContainer>
      </div>
    </Container>
    <Container fluid>
      <Row>
        <Col lg={{ offset: 1, span: 8 }}>
          <FeaturedProducts
            brand="BOSE"
            smItems={1}
            mdItems={2}
            lgItems={3}
          />
        </Col>
        <Col lg={{ offset: 0, span: 2 }}>
          <SecurityCard>
            <Col md={12} className="align-vertical">
              <img
                src="https://http2.mlstatic.com/resources/frontend/homes-korriban/assets/images/ecosystem/protected.svg"
                alt="compras protegida"
              />
            </Col>
            <Col md={12}>
              <h5>Compras seguras</h5>
              <p>
                ¿No te gusta? ¡Devuélvelo! En Sonoritmo, no hay nada que no
                puedas hacer, porque estás siempre protegido
              </p>
              <SecurityModal>
                <Button style={{ fontSize: "12px", marginBottom: "10px" }}>
                  Cómo te protegemos
                </Button>
              </SecurityModal>
            </Col>
          </SecurityCard>
          <SecurityCard>
            <Col md={12} className="align-vertical">
              <img
                src="https://http2.mlstatic.com/resources/frontend/homes-korriban/assets/images/ecosystem/shipping.svg"
                alt="envios gratis"
              />
            </Col>
            <Col md={12}>
              <h5>Envios Gratis</h5>
              <p>
                Con solo comprar en Sonoritmo en linea, tienes envíos gratis
                en todos nuestros productos
              </p>
            </Col>
          </SecurityCard>
        </Col>
      </Row>
    </Container>
  </>
);

const BoseCommercialSection = (
  <>
    <Container fluid>
      <div className="d-none d-lg-block">
        <SplitBannerContainer>
          <SplitBanner lg={12} md={12} className="clickable">
            <img
              src="https://bblanding.s3-us-west-2.amazonaws.com/img/boseinstalacionweb.png"
              alt="somos distribuidores autorizados bose"
            />
          </SplitBanner>
        </SplitBannerContainer>
      </div>
      <div className="d-lg-none d-md-block">
        <SplitBannerContainer>
          <OwlCarousel options={options}>
            <SplitBanner lg={12} md={12}>
              <img
                src="https://bblanding.s3-us-west-2.amazonaws.com/img/boseinstalacionmobil.png"
                alt="somos distribuidores autorizados bose"
              />
            </SplitBanner>
          </OwlCarousel>
        </SplitBannerContainer>
      </div>
    </Container>
    <Container fluid>
      <div className="d-none d-lg-block">
        <SplitBannerContainer>
          <SplitBannerLarge lg={8} md={12} className="clickable">
            <OwlCarousel options={optionCarouselLarge}>
              <LinkToProduct href="/packages/bose-instalacion">
                <div>
                  <img
                    src="https://bblanding.s3-us-west-2.amazonaws.com/img/instalacionbose.png"
                    alt="bose instalacion"
                  />
                </div>
              </LinkToProduct>
            </OwlCarousel>
          </SplitBannerLarge>
          <SplitBannerLarge lg={4} className="clickable">
            <LinkToProduct href="/item/ds40f-bk---bocina-de-plafon-negro----321278-0130--/10082">
              <SplitBannerLargeHalf lg={12} className="clickable">
                <img
                  src="https://bblanding.s3-us-west-2.amazonaws.com/img/comercial1.png"
                  alt="bose f1 en promocion"
                />
              </SplitBannerLargeHalf>
            </LinkToProduct>
            <OwlCarousel
              options={{ ...optionCarouselLarge, autoplayTimeout: 3000 }}
            >
              <LinkToProduct href="/item/802iv---panaray-802iv-loudspeaker---739058-0110--/10285">
                <SplitBannerLargeHalf lg={12} className="clickable">
                  <img
                    src="https://bblanding.s3-us-west-2.amazonaws.com/img/hotelbose.png"
                    alt="backpack s1"
                  />
                </SplitBannerLargeHalf>
              </LinkToProduct>
            </OwlCarousel>
          </SplitBannerLarge>
        </SplitBannerContainer>
      </div>
      <div className="d-lg-none d-md-block">
        <SplitBannerContainer>
          <SplitBannerLarge md={12} className="clickable">
            <OwlCarousel options={{ ...optionCarouselLarge, nav: true }}>
              <LinkToProduct href="/packages/bose-instalacion">
                <div>
                  <img
                    src="https://bblanding.s3-us-west-2.amazonaws.com/img/instalacionbose.png"
                    alt="bose instalacion"
                  />
                </div>
              </LinkToProduct>
            </OwlCarousel>
          </SplitBannerLarge>
        </SplitBannerContainer>
      </div>
      <div className="d-lg-none d-md-block">
        <SplitBannerContainer>
          <SplitBannerLarge md={12} className="clickable">
            <OwlCarousel
              options={{ ...optionCarouselLarge, autoplayTimeout: 3000 }}
            >
              <LinkToProduct href="/item/ds40f-bk---bocina-de-plafon-negro----321278-0130--/10082">
                <img
                  src="https://bblanding.s3-us-west-2.amazonaws.com/img/comercial1.png"
                  alt="backpack s1"
                />
              </LinkToProduct>
              <LinkToProduct href="/item/802iv---panaray-802iv-loudspeaker---739058-0110--/10285">
                <img
                  src="https://bblanding.s3-us-west-2.amazonaws.com/img/hotelbose.png"
                  alt="backpack s1"
                />
              </LinkToProduct>
            </OwlCarousel>
          </SplitBannerLarge>
        </SplitBannerContainer>
      </div>
    </Container>
  </>
);

const PaymentMethods = (
  <Container fluid>
    <PaymentMethodBanner>
      <Col md={6} lg={3} className="align-vertical">
        <h6>Paga cómodo y seguro</h6>
        <p className="sold-items">Con MercadoPago</p>
      </Col>
      <Col md={6} lg={3} className="align-vertical">
        <h6>3, 6 y 12 meses sin intereses</h6>
        <p className="sold-items">En productos seleccionados</p>
      </Col>
      <Col md={12} lg={3} className="align-vertical">
        <i className="fa fa-3x fa-cc-amex"></i>
        <i className="fa fa-fw"></i>
        <i className="fa fa-3x fa-cc-visa"></i>
        <i className="fa fa-fw"></i>
        <i className="fa fa-3x fa-cc-mastercard"></i>
      </Col>
      <PaymentMethodsModal>
        <Col md={12} lg={3} className="align-vertical clickable">
          <i className="fa fa-2x fa-plus-circle"></i>
          <h6>Ver más medios de pago</h6>
        </Col>
      </PaymentMethodsModal>
    </PaymentMethodBanner>
  </Container>
);

const YamahaSection = (
  <>
    <Container fluid>
      <div className="d-lg-none d-md-block">
        <SplitBannerContainer>
          <OwlCarousel options={options}>
            <SplitBanner lg={6} md={12}>
              <img
                src="https://i.postimg.cc/hGHs0WH3/yamahadistmobil.png"
                alt="somos distribuidores autorizados yamaha"
              />
            </SplitBanner>
          </OwlCarousel>
        </SplitBannerContainer>
      </div>
      <div className="d-none d-lg-block">
        <SplitBannerContainer>
          <SplitBannerLarge lg={12} md={12} className="clickable">
            <img
              src="https://i.postimg.cc/ht9Gc3y9/yamahadistribuidor.png"
              alt="l1 compact en promocion"
            />
          </SplitBannerLarge>
        </SplitBannerContainer>
        <SplitBannerContainer>
          <SplitBannerLarge lg={8} md={12} className="clickable">
            <OwlCarousel options={optionCarouselLarge}>
              <LinkToProduct href="/search?text=shs300">
                <div>
                  <img
                    src="https://sonoritmobbpro.s3-us-west-2.amazonaws.com/images/landing_sono/Header-SHS300-WEB-1300x700px.png"
                    alt="YAMAHA SHS300"
                  />
                </div>
              </LinkToProduct>
            </OwlCarousel>
          </SplitBannerLarge>
          <SplitBannerLarge lg={4} className="clickable">
            <LinkToProduct href="/item/p45bspa---piano-digital-negro/20">
              <SplitBannerLargeHalf lg={12} className="clickable">
                <img
                  src="https://i.postimg.cc/SK9ck482/p45small.png"
                  alt="YAMAHA P45BSPA - PIANO DIGITAL NEGRO"
                />
              </SplitBannerLargeHalf>
            </LinkToProduct>
            <OwlCarousel
              options={{ ...optionCarouselLarge, autoplayTimeout: 3000 }}
            >
              <LinkToProduct href="/search?text=hs">
                <SplitBannerLargeHalf lg={12} className="clickable">
                  <img
                    src="https://i.postimg.cc/FzTLDHtq/hsseriessmall.jpg"
                    alt="monitores hs"
                  />
                </SplitBannerLargeHalf>
              </LinkToProduct>
            </OwlCarousel>
          </SplitBannerLarge>
        </SplitBannerContainer>
      </div>
      <div className="d-lg-none d-md-block">
        <SplitBannerContainer>
          <SplitBannerLarge md={12} className="clickable">
            <OwlCarousel options={{ ...optionCarouselLarge, nav: true }}>
              <LinkToProduct href="/search?text=shs300">
                <div>
                <img
                    src="https://sonoritmobbpro.s3-us-west-2.amazonaws.com/images/landing_sono/Header-SHS300-WEB-1300x700px.png"
                    alt="YAMAHA SHS300"
                  />
                </div>
              </LinkToProduct>
            </OwlCarousel>
          </SplitBannerLarge>
        </SplitBannerContainer>
      </div>
      <div className="d-lg-none d-md-block">
        <SplitBannerContainer>
          <SplitBannerLarge md={12} className="clickable">
            <OwlCarousel
              options={{ ...optionCarouselLarge, autoplayTimeout: 3000 }}
            >
              <LinkToProduct href="/item/p45bspa---piano-digital-negro/20">
                <img
                  src="https://i.postimg.cc/SK9ck482/p45small.png"
                  alt="YAMAHA P45BSPA - PIANO DIGITAL NEGRO"
                />
              </LinkToProduct>
              <LinkToProduct href="/search?text=hs">
                <img
                  src="https://i.postimg.cc/FzTLDHtq/hsseriessmall.jpg"
                  alt="monitores hs"
                />
              </LinkToProduct>
            </OwlCarousel>
          </SplitBannerLarge>
        </SplitBannerContainer>
      </div>
    </Container>
    <Container fluid>
      <Row>
        <Col lg={{ offset: 1, span: 10 }}>
          <FeaturedProducts
            brand="EVOLUTION"
            smItems={1}
            mdItems={3}
            lgItems={4}
            modelNotIncludes="PAC"
          />
        </Col>
      </Row>
    </Container>
    <Container fluid>
      <div className="d-lg-none d-md-block">
        <SplitBannerContainer>
          <OwlCarousel options={options}>
            <SplitBanner lg={6} md={12}>
              <img
                src="https://i.postimg.cc/fR1YB2GM/pacbannermobil.jpg"
                alt="somos distribuidores autorizados yamaha"
              />
            </SplitBanner>
          </OwlCarousel>
        </SplitBannerContainer>
      </div>
      <div className="d-none d-lg-block">
        <SplitBannerContainer>
          <SplitBannerLarge lg={12} md={12} className="clickable">
            <img
              src="https://i.postimg.cc/DzsLDQhL/pacbanner.png"
              alt="pac en promocion"
            />
          </SplitBannerLarge>
        </SplitBannerContainer>
        <SplitBannerContainer>
          <SplitBannerLarge lg={8} md={12} className="clickable">
            <OwlCarousel options={optionCarouselLarge}>
              <LinkToProduct href="/item/pac612viifmidb---pac-fica--seymour-duncan--hss-top-maple-indigo-blue/10982">
                <div>
                  <img
                    src="https://i.postimg.cc/tJg1XWKr/paiste3big.jpg"
                    alt="pac612viifmidb"
                  />
                </div>
              </LinkToProduct>
              <LinkToProduct href="/item/pac012dbm---guitarra-electrica/10701">
                <div>
                  <img
                    src="https://i.postimg.cc/s2917Ty6/pac012series.jpg"
                    alt="YAMAHA PAC012SERIES"
                  />
                </div>
              </LinkToProduct>
            </OwlCarousel>
          </SplitBannerLarge>
          <SplitBannerLarge lg={4} className="clickable">
            <LinkToProduct href="/item/pac611vfmcb---pac-fica---pastillas-seymour-duncan--wilkinson--caramel-brown/10980">
              <SplitBannerLargeHalf lg={12} className="clickable">
                <img
                  src="https://i.postimg.cc/mDsX62vZ/pac600.jpg"
                  alt="YAMAHA PAC600"
                />
              </SplitBannerLargeHalf>
            </LinkToProduct>
            <OwlCarousel
              options={{ ...optionCarouselLarge, autoplayTimeout: 3000 }}
            >
              <LinkToProduct href="/item/pac112vmxyns---pacifica--cuerpo-de-alder--push-pull-tap-coil--diapason-de-maple--pickguard-color-negro--color-natur/601">
                <SplitBannerLargeHalf lg={12} className="clickable">
                  <img
                    src="https://i.postimg.cc/RF2cbP67/pac112vmxsmall.jpg"
                    alt="pac112v"
                  />
                </SplitBannerLargeHalf>
              </LinkToProduct>
            </OwlCarousel>
          </SplitBannerLarge>
        </SplitBannerContainer>
      </div>
      <div className="d-lg-none d-md-block">
        <SplitBannerContainer>
          <SplitBannerLarge md={12} className="clickable">
            <OwlCarousel options={{ ...optionCarouselLarge, nav: true }}>
              <LinkToProduct href="/item/pac612viifmidb---pac-fica--seymour-duncan--hss-top-maple-indigo-blue/10982">
                <div>
                  <img
                    src="https://i.postimg.cc/tJg1XWKr/paiste3big.jpg"
                    alt="pac612viifmidb"
                  />
                </div>
              </LinkToProduct>
              <LinkToProduct href="/item/pac012dbm---guitarra-electrica/10701">
                <div>
                  <img
                    src="https://i.postimg.cc/s2917Ty6/pac012series.jpg"
                    alt="YAMAHA pac012series"
                  />
                </div>
              </LinkToProduct>
            </OwlCarousel>
          </SplitBannerLarge>
        </SplitBannerContainer>
      </div>
      <div className="d-lg-none d-md-block">
        <SplitBannerContainer>
          <SplitBannerLarge md={12} className="clickable">
            <OwlCarousel
              options={{ ...optionCarouselLarge, autoplayTimeout: 3000 }}
            >
              <LinkToProduct href="/item/pac611vfmcb---pac-fica---pastillas-seymour-duncan--wilkinson--caramel-brown/10980">
                <img
                  src="https://i.postimg.cc/mDsX62vZ/pac600.jpg"
                  alt="YAMAHA PAC600"
                />
              </LinkToProduct>
              <LinkToProduct href="/item/pac112vmxyns---pacifica--cuerpo-de-alder--push-pull-tap-coil--diapason-de-maple--pickguard-color-negro--color-natur/601">
                <img
                  src="https://i.postimg.cc/RF2cbP67/pac112vmxsmall.jpg"
                  alt="pac112"
                />
              </LinkToProduct>
            </OwlCarousel>
          </SplitBannerLarge>
        </SplitBannerContainer>
      </div>
    </Container>
    <Container fluid>
      <Row>
        <Col lg={{ offset: 1, span: 10 }}>
          <FeaturedProducts
            brand="YAMAHA"
            smItems={1}
            mdItems={3}
            lgItems={4}
            modelIncludes="PAC"
          />
        </Col>
      </Row>
    </Container>
  </>
);

class LandingSonoritmo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      homeData: null
    };
  }

  componentDidMount() {
    cmsService.getHomeConfig()
      .then(response => {
        // do something with the response data
        this.setState({ homeData: response.data.entries });
      })
      .catch(error => {
        // handle the error
        console.log(error);
      });
  }

  mapDataToComponent(componentData) {
    console.log("MAAAAPPPPIIING", componentData)
    if(componentData.type === "BANNERFULL") {
      return (
        <>
        <div className="d-lg-none d-md-block">
        <SplitBannerContainer>
          <OwlCarousel options={options}>
            <SplitBanner lg={6} md={12}>
              <img
                src={CmsUrl + (componentData.mobile_full_banner_image.path.startsWith('/') ? componentData.mobile_full_banner_image.path : '/' + componentData.mobile_full_banner_image.path)}
                alt={componentData.mobile_full_banner_alttext}
              />
            </SplitBanner>
          </OwlCarousel>
        </SplitBannerContainer>
      </div>
      <div className="d-none d-lg-block">
        <SplitBannerContainer>
          <SplitBannerLarge lg={12} md={12} className="clickable">
            <img
              src={CmsUrl + (componentData.web_full_banner_image.path.startsWith('/') ? componentData.web_full_banner_image.path : '/' + componentData.web_full_banner_image.path)}
              alt={componentData.web_full_banner_alttext}
            />
          </SplitBannerLarge>
        </SplitBannerContainer>
      </div>
      </>
      )
    }

    if(componentData.type === "BANNERCOLLECTION") {
      return (
        <>
        <Container fluid>
          <div className="d-none d-lg-block">
            <SplitBannerContainer>
              <SplitBannerLarge lg={8} md={12} className="clickable">
                <OwlCarousel options={optionCarouselLarge}>
                  <LinkToProduct href={componentData.link1}>
                    <div>
                      <img
                        src={CmsUrl+componentData.image1.path}
                        alt={componentData.alttext1}
                      />
                    </div>
                  </LinkToProduct>
                  <LinkToProduct href={componentData.link2}>
                    <div>
                      <img
                        src={CmsUrl+componentData.image2.path}
                        alt={componentData.alttext2}
                      />
                    </div>
                  </LinkToProduct>
                </OwlCarousel>
              </SplitBannerLarge>
              <SplitBannerLarge lg={4} className="clickable">
                <LinkToProduct href={componentData.link3}>
                  <SplitBannerLargeHalf lg={12} className="clickable">
                    <img
                      src={CmsUrl+componentData.image3.path}
                      alt={componentData.alttext3}
                    />
                  </SplitBannerLargeHalf>
                </LinkToProduct>
                <OwlCarousel
                  options={{ ...optionCarouselLarge, autoplayTimeout: 3000 }}
                >
                  <LinkToProduct href={componentData.link4}>
                    <SplitBannerLargeHalf lg={12} className="clickable">
                      <img
                        src={CmsUrl+componentData.image4.path}
                        alt={componentData.alttext4}
                      />
                    </SplitBannerLargeHalf>
                  </LinkToProduct>
                </OwlCarousel>
              </SplitBannerLarge>
            </SplitBannerContainer>
          </div>
          <div className="d-lg-none d-md-block">
            <SplitBannerContainer>
              <SplitBannerLarge md={12} className="clickable">
                <OwlCarousel
                  options={{ ...optionCarouselLarge, autoplayTimeout: 3000 }}
                >
                  <LinkToProduct href={componentData.link1}>
                    <div>
                      <img
                        src={CmsUrl+componentData.image1.path}
                        alt={componentData.alttext1}
                      />
                    </div>
                  </LinkToProduct>
                  <LinkToProduct href={componentData.link2}>
                    <div>
                      <img
                        src={CmsUrl+componentData.image2.path}
                        alt={componentData.alttext2}
                      />
                    </div>
                  </LinkToProduct>
                </OwlCarousel>
              </SplitBannerLarge>
            </SplitBannerContainer>
          </div>
          <div className="d-lg-none d-md-block">
            <SplitBannerContainer>
              <SplitBannerLarge md={12} className="clickable">
                <OwlCarousel options={{ ...optionCarouselLarge, nav: true }}>
                  <LinkToProduct href={componentData.link3}>
                    <div>
                      <img
                        src={CmsUrl+componentData.image3.path}
                        alt={componentData.alttext3}
                      />
                    </div>
                  </LinkToProduct>
                  <LinkToProduct href={componentData.link4}>
                    <div>
                      <img
                        src={CmsUrl+componentData.image4.path}
                        alt={componentData.alttext4}
                      />
                    </div>
                  </LinkToProduct>
                </OwlCarousel>
              </SplitBannerLarge>
            </SplitBannerContainer>
          </div>
        </Container>
        <Container fluid>
          <Row>
            <Col lg={{ offset: 1, span: 10 }}>
              <FeaturedProducts
                brand="PAISTE"
                smItems={1}
                mdItems={3}
                lgItems={4}
              />
            </Col>
          </Row>
        </Container>
        </>
    );
    
    }

    if(componentData.type === "PRODUCTSCARROUSEL") {
      return(
        <Row>
          <Col lg={{ offset: 1, span: 10 }}>
            <FeaturedProducts
              brand={componentData.products_carrousel_brand}
              smItems={1}
              mdItems={3}
              lgItems={4}
            />
          </Col>
        </Row>
      );
    }

    return null
  }

  render() {
    ReactGA.pageview(this.props.location.pathname);
    return (
      <Container fluid>
        <Row className="sonoritmo-bg-color">
          <Loginbar name="Alberto Ayala Capetillo" customer_id="442424" />
          <Navigation auth={this.props.auth} />
        </Row>
        { PaymentMethods }
        {this.state.homeData && this.state.homeData.sort((a, b) => parseInt(a.position) - parseInt(b.position)).map(this.mapDataToComponent)}
        <Footer />
      </Container>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingSonoritmo);
