import React, { Component } from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import ReactGA from 'react-ga';
import { ANALYTICS_KEY, FIREBASE_CONFIG } from './configuration';
import { store, persistor } from './redux/store/configureStore';
import AppRouter from './AppRouter';

class App extends Component {
  componentDidMount() {
    if (ANALYTICS_KEY) {
      ReactGA.initialize(ANALYTICS_KEY);
    }

    if (FIREBASE_CONFIG.apiKey) {
      firebase.initializeApp(FIREBASE_CONFIG);
      firebase.auth().signInAnonymously().catch((error) => {
        console.error(error);
      });
    }
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div className="App products-bg">
            <AppRouter />
            <ToastContainer
              position="bottom-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
          </div>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
