import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';

class ProductCarousel extends Component {
    render() {
        const carouselItems = this.props.photos.map(photo => 
            <Carousel.Item key={photo} className='responsive-image-wrapper'>
                <img
                className="responsive-image"
                src={photo}
                alt="not-found"
                />
            </Carousel.Item>
        )
        return (
            <Carousel>
                {carouselItems}
            </Carousel>
        );
    }
}

export default ProductCarousel;



