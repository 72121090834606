import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loginbar from '../../../shared/professional/Loginbar/Loginbar';
import Navigation from '../../../shared/sonoritmo/Navigation/Navigation';
import Footer from '../../../shared/footer/footer';
import ReactGA from 'react-ga';

const Stores = ({auth, location}) => {
    ReactGA.pageview(location.pathname);
    return(
        <Container fluid>
            <Row className="sonoritmo-bg-color">
              <Loginbar name="Alberto Ayala Capetillo" customer_id="442424"/>
              <Navigation category={"stores"} auth={auth}/>
            </Row>
            <Row className="h-100 products-content stores-container">
                <Col md="12" className="store-image no-padding" as="a" href="/sucursales/jardin">
                    <div className="store-card-outer-div" style={{height:"80vh"}} >
                        <div className="store-card-inner-div" style={{ backgroundImage: "url('https://bbay.icu/cms/storage/uploads/2023/05/18/WhatsApp-Image-2023-05-17-at-21.29.19_uid_64659e8f37820.jpeg')"}}>
                            <h1>Bitstruments Jardin Balbuena</h1>
                        </div>
                    </div>
                </Col>
            </Row>
            <Footer/> 
        </Container>
    );
}

export default Stores;