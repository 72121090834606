import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Feedback from 'react-bootstrap/Feedback';
import Loader from '../../shared/loader/loader';
import { createOrder } from '../../redux/actions';
import { currencyFormat } from '../../utils/format';

class PurchaseModal extends Component {

  constructor(props) {
    super(props);
    this.props = props;
    this.clickHandler = this.clickHandler.bind(this);
    this.state = {
      availablePoints: 0,
      step: 0,
      delivery: false,
      store: false,
      validStep: false,
      pickInStore: {
        name: "",
        phone: "",
        store: "",
      },
      shipping: {
        name: "",
        address: "",
        district: "",
        city: "",
        state: "",
        zipCode: "",
        phone: "",
      },
      paymentMethod: "mercadopago",
      kart: this.props.kart,
      email: this.props.profile.email,
      canHaveInstallmentsWithoutInterest: this.props.canHaveInstallmentsWithoutInterest,
    }

    this.selectShippingMethod = this.selectShippingMethod.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.handleInStoreFormChange = this.handleInStoreFormChange.bind(this);
    this.handleShippingFormChange = this.handleShippingFormChange.bind(this);
    this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this);
    this.handleOrderCreation = this.handleOrderCreation.bind(this);
  }

    clickHandler(){
      this.props.onHide();
    }

    validateStep(step, state){
      if(step === 0) return state.delivery || state.store
      if(step === 1  && state.store){
          return state.pickInStore.name.length >= 4 && state.pickInStore.phone.length >= 8 && state.pickInStore.store.length > 0;
      }
      if(step === 1  && state.delivery){
        return state.shipping.name.length >= 4
          && state.shipping.address.length >= 4
          && state.shipping.district.length >= 4
          && state.shipping.city.length >= 4
          && state.shipping.state.length >= 4
          && state.shipping.zipCode.length >= 5
          && state.shipping.phone.length >= 8;
      }
      return true;
    }

    nextStep() {
      this.setState({...this.state, step: this.state.step + 1, validStep: false, isLoading: false});
    }

    previousStep() {
      this.setState({...this.state, step: this.state.step -1, validStep: true})
    }

    selectShippingMethod(e) {
      let newState = {...this.state}
      
      if(e.target.id === "delivery" && !this.state.delivery){
        newState = {...newState, delivery: !this.state.delivery, store: false};
      } else if (e.target.id === "store" && !this.state.store) {
        newState = {...newState, delivery: false, store: !this.state.store};
      } else {
        newState = {...newState, delivery: false, store: false};
      }
      
      newState.validStep = this.validateStep(newState.step, newState);
      this.setState(newState);
    }

    handleShippingFormChange(e){
      const { shipping } = this.state;
      
      shipping[e.target.id] = e.target.value;
      
      const newState = {...this.state, shipping};
      newState.validStep = this.validateStep(newState.step, newState);
      
      this.setState(newState);
    }

    handleInStoreFormChange(e){
      const { pickInStore } = this.state;
      
      if(e.target.name === "store") pickInStore.store = e.target.id;
      else pickInStore[e.target.id] = e.target.value;
      
      const newState = {...this.state, pickInStore: pickInStore};
      newState.validStep = this.validateStep(newState.step, newState);
      
      this.setState(newState);
    }

    handlePaymentMethodChange(e) {
      const newState = {...this.state};
      newState.paymentMethod = e.target.id;
      newState.validStep = this.validateStep(newState.step, newState);
      
      this.setState(newState);
    }

    handleOrderCreation() {
      let orderPayload = JSON.parse(JSON.stringify(this.state));
      orderPayload.kart = orderPayload.kart.map(item => {
        delete item.description;

        return item;
      });
      this.props.createOrder(orderPayload);
      this.setState({...this.state, isLoading: true});
    }


    render() {
      return (
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Completar compra
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {this.state.step === 0 ?
                (<React.Fragment>
                  <Col md={12}>
                    <h6>1. Selecciona el metodo de envio</h6>
                  </Col>
                  <Col md={12}>
                    <Form>
                      <Form.Group>
                        <Form.Check checked={this.state.delivery} id="delivery" label="Envio a domicilio" onChange={this.selectShippingMethod}/>
                        <Form.Check checked={this.state.store} id="store" label="Recoger en tienda" onChange={this.selectShippingMethod}/>
                      </Form.Group>
                    </Form>
                  </Col>
                </React.Fragment>
                ) : null
              }
              { this.state.step === 1 && this.state.store ?
                (<React.Fragment>
                  <Col md={12}>
                    <h6>2. Datos de entrega</h6>
                  </Col>
                  <Col md={12}>
                    <Form onChange={this.handleInStoreFormChange}>
                      <Form.Group controlId="name">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control type="text" isInvalid={this.state.pickInStore.name.length < 4} value={this.state.pickInStore.name}/>
                        <Form.Text  className="text-muted">
                          Nombre de la persona que acudira a la sucursal
                        </Form.Text>
                        {
                          this.state.pickInStore.name.length < 4 && 
                          <Form.Control.Feedback type="invalid">
                            Nombre no válido (4 caracteres mínimo)
                          </Form.Control.Feedback>
                        }
                      </Form.Group>
                      <Form.Group controlId="phone">
                        <Form.Label>Numero de contacto</Form.Label>
                        <Form.Control type="phone" isInvalid={this.state.pickInStore.phone.length < 8} value={this.state.pickInStore.phone} placeholder="55 5555 5555" />
                        <Form.Text className="text-muted">
                          Numero telefonico para contactarte
                        </Form.Text>
                        {
                          this.state.pickInStore.phone.length < 8 && 
                          <Feedback type="invalid">
                            Numero de contacto no válido (8 digitos mínimo)
                          </Feedback>
                        }
                      </Form.Group>
                      <h6>Selecciona la sucursal</h6>
                      <fieldset>
                        <Form.Group>
                          <Form.Check type="radio" name="store" id="balbuena">
                            <Form.Check.Input isInvalid={!this.state.pickInStore.store} type="radio" name="store" id="balbuena" checked={this.state.pickInStore.store === "balbuena"}/>
                            <Form.Check.Label>
                              Retorno 11 Calzada Ignacio Zaragoza #47 local B, Jardin Balbuena, Venustiano Carranza, CDMX
                            </Form.Check.Label>
                          </Form.Check>
                        </Form.Group>
                      </fieldset>
                    </Form>
                  </Col>
                </React.Fragment>) : null }
                { this.state.step === 1 && this.state.delivery ?
                (<React.Fragment>
                  <Col md={12}>
                    <h6>2. Capturar datos de envio</h6>
                  </Col>
                  <Col md={12}>
                    <Form onChange={this.handleShippingFormChange}>
                      <Form.Group controlId="name">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control type="text" isInvalid={this.state.shipping.name.length < 4} value={this.state.shipping.name}/>
                        <Form.Text className="text-muted">
                          Nombre de la persona que recibira el envio
                        </Form.Text>
                        {
                          this.state.shipping.name.length < 4 && 
                          <Form.Control.Feedback type="invalid">
                            Nombre no válido (4 caracteres mínimo)
                          </Form.Control.Feedback>
                        }
                      </Form.Group>
                      <Form.Group controlId="address">
                        <Form.Label>Dirección</Form.Label>
                        <Form.Control type="text" isInvalid={this.state.shipping.address.length < 4} value={this.state.shipping.address}/>
                        <Form.Text className="text-muted">
                          ej. Alvaro Obregon 95
                        </Form.Text>
                        {
                          this.state.shipping.address.length < 4 && 
                          <Form.Control.Feedback type="invalid">
                            Dirección no válida (4 caracteres mínimo)
                          </Form.Control.Feedback>
                        }
                      </Form.Group>
                      <Form.Group controlId="district">
                        <Form.Label>Colonia</Form.Label>
                        <Form.Control type="text" isInvalid={this.state.shipping.district.length < 4} value={this.state.shipping.district}/>
                        <Form.Text className="text-muted">
                          ej. Roma Norte
                        </Form.Text>
                        {
                          this.state.shipping.district.length < 4 && 
                          <Form.Control.Feedback type="invalid">
                            Colonia no válida (4 caracteres mínimo)
                          </Form.Control.Feedback>
                        }
                      </Form.Group>
                      <Form.Group controlId="city">
                        <Form.Label>Delegacion / Municipio</Form.Label>
                        <Form.Control type="text" isInvalid={this.state.shipping.city.length < 4} value={this.state.shipping.city}/>
                        <Form.Text className="text-muted">
                          ej. Cuauhtemoc
                        </Form.Text>
                        {
                          this.state.shipping.city.length < 4 && 
                          <Form.Control.Feedback type="invalid">
                            Delegacion / Municipio no válido (4 caracteres mínimo)
                          </Form.Control.Feedback>
                        }
                      </Form.Group>
                      <Form.Group controlId="state">
                        <Form.Label>Estado / Ciudad</Form.Label>
                        <Form.Control type="text" isInvalid={this.state.shipping.state.length < 4} value={this.state.shipping.state}/>
                        <Form.Text className="text-muted">
                          ej. Ciudad de Mexico
                        </Form.Text>
                        {
                          this.state.shipping.state.length < 4 && 
                          <Form.Control.Feedback type="invalid">
                            Estado / Ciudad no válida (4 caracteres mínimo)
                          </Form.Control.Feedback>
                        }
                      </Form.Group>
                      <Form.Group controlId="zipCode">
                        <Form.Label>Codigo Postal</Form.Label>
                        <Form.Control type="text" isInvalid={this.state.shipping.zipCode.length < 5} value={this.state.shipping.zipCode}/>
                        <Form.Text className="text-muted">
                          ej. 03020
                        </Form.Text>
                        {
                          this.state.shipping.zipCode.length < 5 && 
                          <Form.Control.Feedback type="invalid">
                            Codigo Postal no válido (5 caracteres mínimo)
                          </Form.Control.Feedback>
                        }
                      </Form.Group>
                      <Form.Group controlId="phone">
                        <Form.Label>Numero de contacto</Form.Label>
                        <Form.Control type="phone" isInvalid={this.state.shipping.phone.length < 8} value={this.state.shipping.phone} placeholder="55 5555 5555" />
                        <Form.Text className="text-muted">
                          Numero telefonico para contactarte
                        </Form.Text>
                        {
                          this.state.shipping.phone.length < 8 && 
                          <Form.Control.Feedback type="invalid">
                            Numero de contacto no válido (8 digitos mínimo)
                          </Form.Control.Feedback>
                        }
                      </Form.Group>
                    </Form>
                  </Col>
                </React.Fragment>) : null }
                { this.state.step === 2 ?
                (<React.Fragment>
                  <Col md={12}>
                    <h5>Total ${currencyFormat(this.props.total > 0 ? this.props.total : 0)}</h5>
                  </Col>
                  <Col md={12}>
                    Al pulsar pagar te redirigiremos al sitio de pago seguro de mercadopago
                  </Col>
                </React.Fragment>) : null }
            </Row>
          </Modal.Body>
          <Modal.Footer>
            { this.state.step && !this.props.isLoading > 0 ? <Button onClick={this.previousStep}>Regresar</Button> : null }
            { this.state.step < 2 ? <Button onClick={this.nextStep} disabled={!this.state.validStep}>Siguiente</Button> : null }
            { this.state.step === 2 &&  !this.props.isLoading && <Button onClick={this.handleOrderCreation}>Pagar</Button>  }
            { this.state.step === 2 && this.props.isLoading ? <Loader className="no-margin"/> : null }
          </Modal.Footer>
        </Modal>
      );
    }
}

const mapStateToProps = function(state) {
  return {
    kart: state.global.kart,
    profile: state.global.profile,
    isLoading: state.global.isLoading
  }
}
  
const mapDispatchToProps = dispatch => ({
  createOrder: (orderPayload) => dispatch(createOrder(orderPayload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseModal);
