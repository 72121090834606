import React from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';

const StyledRow = styled(Row)`
    background-color: #474747;
    font-size: 10px;
    bottom: 0;
    color: white;

    .head {
        font-size: 12px;
        padding-top: 10px;
    }

    .copyright {
        background-color: #38373C;
        font-size: 8px;
        bottom: 0;
    }
`;

const Footer = () => (
    <React.Fragment>
        <StyledRow>
            <Col md={4}>
                <h6 className='head'>CONTÁCTANOS</h6>
                <p>
                Retorno 11 Calzada Ignacio Zaragoza #47 local B <br/>
                C.P 15900 Delegacion Venustiano Carranza<br/>
                Ciudad De Mexico<br/>
                Tel: 55 3475 6429
                </p>
            </Col>
            <Col md={4}>
                <h6 className='head'>AVISOS DE PRIVACIDAD</h6>
                <p>
                    <a href="#" target="blank" className="white-text with-padding-right">
                        <i className="fa fa-file-text-o fa-2x clickable"></i> 
                    </a> 
                    <a href="#" target="blank" className="white-text with-padding-right">
                        <i className="fa fa-file-pdf-o fa-2x clickable"></i> 
                    </a>
                </p>
            </Col>
            <Col md={4}>
                <h6 className='head'>SÍGUENOS</h6>
                <p>
                    <a href="https://www.facebook.com/bitstruments" target="blank" className="white-text with-padding-right">
                        <i className="fa fa-facebook-square fa-2x clickable"></i> 
                    </a> 
                    <a href="https://twitter.com/bitstruments" target="blank" className="white-text with-padding-right">
                        <i className="fa fa-twitter-square fa-2x clickable"></i> 
                    </a> 
                    <a href="https://www.instagram.com/bitstruments/" target="blank" className="white-text">
                        <i className="fa fa-instagram fa-inverse fa-2x clickable"></i> 
                    </a>
                </p>
                
            </Col>
        </StyledRow>
        <StyledRow>
            <Col md={12} className="copyright">© 2023 Bitstruments  /  Todos los derechos reservados D.R. ®</Col>
        </StyledRow>
        <StyledRow>
            <Col md={12} className="copyright">Powered by BusinessBay ©</Col>
        </StyledRow>
    </React.Fragment>
    
);

export default Footer;
