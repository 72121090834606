import React, { Component } from 'react';
import { Router, Route, Switch } from "react-router-dom";
import Stores from './pages/sonoritmo/stores/stores';
import StoreDetail from './pages/sonoritmo/stores/storeDetail';
import SonoritmoExplorer from './pages/sonoritmo/explorer/Explorer';
import Advertisement from './pages/sonoritmo/advertisement/Advertisement';
import Search from './pages/sonoritmo/search/Search';
import SonoritmoKart from './pages/sonoritmo/kart/Kart';
import LandingSonoritmo from './pages/sonoritmo/landing/LandingSonoritmo';
import PaymentResponse from './shared/paymentResponse/PaymentResponse';
import Item from './pages/sonoritmo/item/Item'; 
import Auth from './auth/auth';
import history from './history';
import LiveChat from './shared/chat/livechat';
import Orders from './pages/sonoritmo/orders/Orders';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

const auth = new Auth();

const handleAuthentication = (nextState, replace) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.handleAuthentication();
  }
}

class AppRouter extends Component {
  
  render() {
    return (
        <>
        <Router history={history}>
          <Switch>
            <Route path="/" exact component={(props) => <LandingSonoritmo auth={auth} {...props} />}/>
            <Route path="/callback" exact component={(props) => {
              handleAuthentication(props);
              return (<div>Loading ...</div>)
            }}/>
            <Route exact path="/success"  component={(props) => <PaymentResponse auth={auth} {...props} />}></Route>
            <Route exact path="/pending"  component={(props) => <PaymentResponse auth={auth} {...props} />}></Route>
            <Route exact path="/error"  component={(props) => <PaymentResponse auth={auth} {...props} />}></Route>
            <Route exact path="/ordenes"  component={(props) => <Orders auth={auth} {...props} />}></Route>
            <Route exact path="/sucursales"  component={(props) => <Stores auth={auth} {...props} />}></Route>
            <Route exact path="/sucursales/:storeName"  component={(props) => <StoreDetail auth={auth} {...props} />}></Route>
            <Route exact path="/kart"  component={(props) => <SonoritmoKart auth={auth} {...props} />}></Route>
            <Route exact path="/item/:itemmodel/:itemhash" component={(props) => <Item auth={auth} {...props} type="item" />}/>
            <Route exact path="/pack/:itemmodel/:itemhash" component={(props) => <Item auth={auth} {...props} type="pack"/>}/>
            <Route path="/search" component={(props) => <Search auth={auth} {...props} />}/>
            <Route path="/packages/:category" component={(props) => <Advertisement auth={auth} {...props} />}/>
            <Route path="/:category" component={(props) => <SonoritmoExplorer auth={auth} {...props} />}/>
          </Switch>
        </Router>
        <div style={{zIndex: 20, position:"fixed"}}>
          <WhatsAppWidget 
            companyName="Bitstruments"  
            phoneNumber="+525534756429" 
            replyTimeText=""
            message="Hola, como te puedo ayudar?"
            sendButtonText="enviar"
            inputPlaceHolder="Escribe tu mensaje"
          />
        </div>
        </>
    );
  }
}

export default AppRouter;
