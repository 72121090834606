import React, {useState} from 'react';
import { Modal, Container, Row, Col, Form, Button } from 'react-bootstrap';

const RewardsExchangeModal = ({children, userProfile, submitHandler}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const OpenModalTrigger = React.cloneElement(children, {
      onClick: handleShow,
    });

    let code;
    let points;

    const submit = (event) => {
        event.preventDefault();
        if(code.value && points.value){
          submitHandler(userProfile.id, code.value, points.value);
          handleClose();
        }
    }

    return (
      <>
        {OpenModalTrigger}
        <Modal
          size="md"
          show={show}
          onHide={handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Canjear Sonopuntos
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container style={{padding: "0px 20px"}}>
                <Row>
                    <Col>
                        <Form onSubmit={submit}>
                            <Form.Group>
                                <Form.Label>Ingrese el codigo del ticket</Form.Label>
                                <Form.Control type="text" placeholder="XXXXX-XXXXXXXXXX-XXXXX" ref={(input) => { code = input; }}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Ingrese el numero de puntos obtenidos</Form.Label>
                                <Form.Control type="number" min="1" ref={(input) => { points = input; }}/>
                            </Form.Group>
                            <Button variant="primary" type="submit" onClick={submit} block>
                                Canjear
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </>
    );
}

export default RewardsExchangeModal;
