import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loginbar from '../../../shared/professional/Loginbar/Loginbar';
import Navigation from '../../../shared/sonoritmo/Navigation/Navigation';
import Footer from '../../../shared/footer/footer';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import { Markup } from 'interweave';


const stores = {
    jardin: {
        name: "Bitstruments Jardin Balbuena",
        address: `Retorno 11 Calzada Ignacio Zaragoza #47 local B C.P 15900 Delegacion Venustiano Carranza<br/>
        Ciudad De Mexico<br/>
        Tel: 55 3475 6429`,
        pictures: [
            'https://bbay.icu/cms/storage/uploads/2023/05/18/WhatsApp-Image-2023-05-17-at-21.28.53_uid_64659e9089ad2.jpeg',
            'https://bbay.icu/cms/storage/uploads/2023/05/18/WhatsApp-Image-2023-05-17-at-21.29.19_uid_64659e8f37820.jpeg',
            'https://bbay.icu/cms/storage/uploads/2023/05/18/WhatsApp-Image-2023-05-17-at-21.29.18_uid_64659e8fe93b1.jpeg',
        ],
        route: 'https://www.google.com/maps/dir//bitstruments,+Rtno.+11+Calzada+Gral.+Ignacio+Zaragoza+47-local+b,+Jard%C3%ADn+Balbuena,+Venustiano+Carranza,+15900+Ciudad+de+México,+CDMX/@19.4209893,-99.1034953,17z/data=!4m17!1m7!3m6!1s0x85d1fd886aa3f2e1:0xd53901c4a7150df1!2sbitstruments!8m2!3d19.4209893!4d-99.1009204!16s%2Fg%2F11sttlll_c!4m8!1m0!1m5!1m1!1s0x85d1fd886aa3f2e1:0xd53901c4a7150df1!2m2!1d-99.1009204!2d19.4209893!3e2',
        map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.8013021496467!2d-99.10349532451059!3d19.420989281855988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1fd886aa3f2e1%3A0xd53901c4a7150df1!2sbitstruments!5e0!3m2!1sen!2smx!4v1684381530205!5m2!1sen!2smx',
    }
}

const StoreCarousel = ({photos}) => {

    const carouselItems = photos.map(photo => 
        <Carousel.Item key={photo} className="store-carousel">
            <div
                className="store-fill-image"
                style={{ backgroundImage: `url('${photo}')`}}
            >
            </div>
        </Carousel.Item>
    )

    return (
        <Carousel>
            {carouselItems}
        </Carousel>
    );
}

const StoreDetail = ({auth, match}) => {
    return(
        <Container fluid>
            <Row className="sonoritmo-bg-color">
              <Loginbar name="Alberto Ayala Capetillo" customer_id="442424"/>
              <Navigation category={"stores"} auth={auth}/>
            </Row>
            { stores[match.params.storeName] ?
            (<Row className="products-content bg-gray">
                <Col md="12" className="no-padding">
                    <StoreCarousel photos={stores[match.params.storeName].pictures}/>
                </Col>
                <Col md={{offset: 1, span: 6}} className="store-address">
                    <h4>{stores[match.params.storeName].name}</h4>
                    <p className="address">
                        <Markup content={stores[match.params.storeName].address}/>
                    </p>
                </Col>
                <Col className="store-address">
                    <Button className="button" size="lg" as="a" target="_blank" href={stores[match.params.storeName].route}>CÓMO LLEGAR</Button>
                </Col>
                <Col md="12" className="iframe-maps">
                    <iframe title="store-map" src={stores[match.params.storeName].map} width="600" height="450" frameBorder="0"  allowFullScreen></iframe>
                </Col>
            </Row>) :
            (<Row className="products-content">Página no encontrada</Row>)
            }
            <Footer/> 
        </Container>
    );
}

export default StoreDetail;