import React, { Component } from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import CollapsibleFilterList from '../collapsibleFilterList/collapsibleFilterList';
import {
    getProducts,
    getSubcategories,
    selectSubcategory,
    getBrands,
    selectBrand,
    getTags,
    selectTag
} from '../../redux/actions';
import ProductList from '../productList/ProductList';

class ProductExplorer extends Component {

    constructor(props) {
        super(props);
        this.props = props;
        
        this.state = {
            isLoading: false,
            products: [],
            selectedBrand: this.props.brand ? this.props.brand : 'bose',
            selectedCategory: this.props.category ? this.props.category : 'guitarras',
            modalShow: false,
            currentPage: this.props.currentPage ? parseInt(this.props.currentPage) : 1,
            pages: this.props.pages,
            page: this.props.page,
            open: false,
        };

        this.changeSubcategoryFilter = this.changeSubcategoryFilter.bind(this);
        this.changeBrandFilter = this.changeBrandFilter.bind(this);
        this.changeTagFilter = this.changeTagFilter.bind(this);
    }

    componentDidMount() {
        this.props.selectSubcategory(this.state.selectedCategory, {subcategory: this.props.subcategory ? this.props.subcategory : 'all'});
        this.props.getSubcategories(this.state.selectedCategory);
        this.props.getBrands(this.state.selectedCategory);
    }

    changeSubcategoryFilter(subcategory) {
        this.props.selectSubcategory(this.state.selectedCategory, subcategory);
    }

    changeBrandFilter(brand) {
        this.props.selectBrand(this.state.selectedCategory, brand);
    }

    changeTagFilter(tag) {
        this.props.selectTag(this.state.selectedCategory, tag);
    }

    render() {
        const {products, page, pages, currentPage, category, nextPage} = this.props;
        const { open } = this.state;

        return (
            <Row className="h-100 products-content">
                <Col xs="12" className="d-xs-block d-md-none no-padding">
                    <Row noGutters>
                        <Col xs={{ span: 12, offset: 0 }}>
                            <Button
                                className="filters-button"
                                onClick={() => this.setState({...this.state, open: !open})}
                                aria-controls="collapsible-filters"
                                aria-expanded={open}
                            >
                                <i className="fa fa-filter"></i>
                            </Button>
                        </Col>
                    </Row>
                    <Collapse in={open}>
                        <div id="collapsible-filters">
                            <CollapsibleFilterList title="Categorias" options={this.props.subcategories} renderKey="subcategory" handler={this.changeSubcategoryFilter} optionSelected={this.props.selectedSubcategory}/>
                            {this.props.tags.length === 0 ? null : (<CollapsibleFilterList title="Filtrar" options={this.props.tags} renderKey="tag" handler={this.changeTagFilter} optionSelected={this.props.selectedTag}/>)}
                            <CollapsibleFilterList title="Marcas" options={this.props.brands} renderKey="brand" handler={this.changeBrandFilter} optionSelected={this.props.selectedBrand}/>
                        </div>
                    </Collapse>
                </Col>
                <Col md="2" className="filter-bar d-none d-md-block">
                    <CollapsibleFilterList title="Categorias" options={this.props.subcategories} renderKey="subcategory" handler={this.changeSubcategoryFilter} optionSelected={this.props.selectedSubcategory}/>
                    {this.props.tags.length === 0 ? null : (<CollapsibleFilterList title="Filtrar" options={this.props.tags} renderKey="tag" handler={this.changeTagFilter} optionSelected={this.props.selectedTag}/>)}
                    <CollapsibleFilterList title="Marcas" options={this.props.brands} renderKey="brand" handler={this.changeBrandFilter} optionSelected={this.props.selectedBrand}/>
                </Col>
                <ProductList category={category} products={products} page={page} pages={pages} currentPage={currentPage} nextPage={nextPage} paginationBgColor="#008A9D" paginationFontColor="black"/>
            </Row>
        );
    }
}

const mapStateToProps = function(state) {
    return {
      isLoading: state.global.isLoading,
      products: state.commerce.products,
      pages: state.commerce.pages,
      previousPage: state.commerce.previousPage,
      nextPage: state.commerce.nextPage,
      subcategories: state.commerce.subcategories,
      selectedSubcategory: state.commerce.selectedSubcategory,
      brands: state.commerce.brands,
      selectedBrand: state.commerce.selectedBrand,
      tags: state.commerce.tags,
      selectedTag: state.commerce.selectedTag,
      kart: state.global.kart,
      professionalKart: state.global.professionalKart,
    }
}

const mapDispatchToProps = dispatch => ({
    getProducts: (selectedCategory, currentPage, selectedSubcategory) => dispatch(getProducts(selectedCategory, currentPage, selectedSubcategory)),
    selectSubcategory: (selectedCategory, subcategory) => dispatch(selectSubcategory(selectedCategory, subcategory)),
    getSubcategories: selectedCategory => dispatch(getSubcategories(selectedCategory)),
    getBrands: selectedCategory => dispatch(getBrands(selectedCategory)),
    selectBrand: (selectedCategory, selectedBrand) => dispatch(selectBrand(selectedCategory, selectedBrand)),
    getTags: (selectedCategory, selectedSubcategory) => dispatch(getTags(selectedCategory, selectedSubcategory)),
    selectTag: (selectedCategory, selectedTag) => dispatch(selectTag(selectedCategory, selectedTag)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductExplorer);
