import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const getGeneralMessagesSnapshot = (userId) => {
    firebase.auth().signInAnonymously().catch(function(error) {
        console.error(error);
    });
    
    var db = firebase.firestore();
    
    return db.collection(`chats/general/${userId}`).orderBy('id', 'desc').limit(100)
}

const sendGeneralMessage = (userId, message) => {
    firebase.auth().signInAnonymously().catch(function(error) {
        console.error(error);
    });
    
    var db = firebase.firestore();
    db.doc(`chats/general`).get().then((doc) => 
        {
            const chatData = doc.data();
            chatData.activeUsers = chatData.activeUsers ? [...new Set([...chatData.activeUsers, userId])] : [userId];
            if(chatData.lastMessages)
                chatData.lastMessages[userId] = message;
            else{
                chatData.lastMessages = {};
                chatData.lastMessages[userId] = message;
            }
            db.doc(`chats/general`).update(chatData);
        }
    );
    return db.collection(`chats/general/${userId}`).doc(message.id+"").set(message)
}

export const chatService = {
    getGeneralMessagesSnapshot,
    sendGeneralMessage
}