import { API_URL } from '../configuration';
import axios from 'axios';

function getProfile() {
    const token = localStorage.getItem('access_token');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    
    return axios.get(`${API_URL}/users/profile`, config);
}

export const userService = {
    getProfile,
};