import { BUSINESSBAY_API } from '../configuration';
import axios from 'axios';

function getAll() {
    const token = localStorage.getItem('access_token');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    
    return axios.get(`${BUSINESSBAY_API}/packages/all`, config);
}

function getById(advertisementId) {
    const token = localStorage.getItem('access_token');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    
    return axios.get(`${BUSINESSBAY_API}/packages?id=${advertisementId}`, config);
}

export const advertisementService = {
    getAll,
    getById,
};