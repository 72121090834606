import React, { Component } from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { currencyFormat } from '../../utils/format';
import PurchaseModal from '../purchaseModal/PurchaseModal';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { removeItemFromKart, updateItemKartAmount } from '../../redux/actions';
import Container from 'react-bootstrap/Container';

class KartList extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.login = this.login.bind(this);
        this.state = {
            modalShow: false,
        };

        this.updateItemAmount = this.updateItemAmount.bind(this);
    }

    componentDidMount(){
        if(this.isAuthenticated()) {
            this.props.getProfile();
        }
    }

    login(){
        this.props.auth.login();
    }

    getRealPrice(product){
        return parseFloat(product.price_with_discount) <= parseFloat(product.price) ? parseFloat(product.price_with_discount) : parseFloat(product.price);
    }

    updateItemAmount(product, amount){
      amount = parseInt(amount)
      if(amount >= 1 && amount <= parseInt(product.stock)) {
        this.props.updateItemKartAmount("kart", product, amount)
      } else {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }

    buildKartItem(product) {
        const price = this.getRealPrice(product);
        
        return (<Row key={product.id}>
            <Col md={12} className="kart-item item-padding">
                <Row>
                    <Col md={5} className="d-none d-sm-block"></Col>
                    <Col md={3} className="d-none d-sm-block">Precio Unitario</Col>
                    <Col md={2} className="d-none d-sm-block"></Col>
                    <Col md={2} className="d-none d-sm-block">Cantidad</Col>
                </Row>
                <Row>
                    <Col md={5} xs={12}>
                        <img alt="product" src={product.photos[0]} className="kart-item-photo"/>
                        <div>{product.model}</div>
                        <div className="text-bolder">Máx. disponibles: {product.stock}</div>
                    </Col>
                    <Col md={3} xs={12}>$ {currencyFormat(price)}</Col>
                    <Col md={2} xs={12}><i className="fa fa-trash clickable" onClick={(e) => this.props.removeItemFromKart("kart", product.id)}></i></Col>
                    <Col xs={12} className="d-sm-block d-md-none">Cantidad</Col>
                    <Col md={{span: 2, offset: 0}} xs={{span: 4, offset: 4}}>
                      <InputGroup>
                          <FormControl className="center-text"
                              id={product.id}
                              type="number"
                              min={1}
                              max={product.stock}
                              defaultValue={product.amount}
                              onChange={(e)=>{
                                this.updateItemAmount(product, e.target.value);
                              }}
                          />
                      </InputGroup>
                    </Col>
                </Row>
            </Col>
        </Row>);
    }

    getKartItems() {
        return this.props.kart.sort((a,b)=> b.id - a.id).map(product => this.buildKartItem(product));
    }

    getSubtotal() {
        return this.props.kart.reduce((prev,next) => prev + next.amount * this.getRealPrice(next), 0);
    }

    getTotal() {
        return this.props.kart.reduce((prev,next) => prev + next.amount * this.getRealPrice(next), 0);
    }

    isAuthenticated(){
        return this.props.auth.isAuthenticated();
    }

    canHaveInstallmentsWithoutInterest() {
        return this.props.kart.reduce((prev,next) => prev && next.hasInstallments, true)
    }

    getProductsCantHaveInstallmentsWithoutInterest() {
        return this.props.kart.filter(item => !item.hasInstallments).map(
            it => <li>{it.model}</li>
        )
    }
    
    render() {
        let modalClose = () => this.setState({ ...this.state, modalShow: false });
        
        let showModal = () => {
            if(this.props.profile.id) {
                this.setState({ ...this.state, modalShow: true });
            } else {
                console.error("COULDN'T GET USER PROFILE");
            }
        };
        
        const items = this.getKartItems();
        const subtotal = this.getSubtotal();
        const total = this.getTotal();
        const canHaveInstallmentsWithoutInterest = this.canHaveInstallmentsWithoutInterest();
        const productsCantHaveInstallmentsWithoutInterest = this.getProductsCantHaveInstallmentsWithoutInterest();

        return (
            <Container className="products-bg" fluid>
                <Col className="h-100 products-content">
                    <Row hidden={items.length === 0}>
                        <Col md={{ span: 5, offset: 1 }} className="d-none d-md-block">
                            {items}
                        </Col>
                        <Col md={{ span: 4, offset: 1 }} xs={12} className="purchase-summary">
                            <Row className="center-text">
                                <h4 className="w-100">Resumen de compra</h4>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    Subtotal
                                </Col>
                                <Col md={8} className="final-price">
                                    $ {currencyFormat(subtotal)}
                                </Col>
                                <Col md={4} className="final-price">
                                    Total
                                </Col>
                                <Col md={8} className="final-price">
                                    $ {currencyFormat(total)}
                                </Col>
                                {canHaveInstallmentsWithoutInterest ? (
                                <>
                                    <Col md={4} className="final-price icon-success">
                                        12 meses sin intereses con tarjeta de credito
                                    </Col>
                                    <Col md={8} className="final-price icon-success">
                                        $ {currencyFormat(total/12)}
                                    </Col>
                                </>
                                ): null }
                                {
                                    !canHaveInstallmentsWithoutInterest && this.props.kart.length > 1 ?
                                    (<Col md={12} className="icon-info">
                                        <br/>Los siguiente(s) producto(s) no aplican para meses sin intereses:
                                        {productsCantHaveInstallmentsWithoutInterest}
                                        Para obtener meses sin intereses puede removerlo(s) de su carrito y comprarlos por separado o si lo desea puede proceder con la compra a precio normal
                                    </Col>) : null
                                }
                            </Row>
                            <Row>
                                <Col md={{ span: 6, offset: 3 }} className="with-margin-top">
                                    { !this.isAuthenticated() ?
                                        <Button onClick={this.login}>
                                            Comprar
                                        </Button> :
                                        <Button onClick={showModal}>
                                            Comprar
                                        </Button>
                                    }
                                </Col>
                                <Col md={{ span: 12, offset: 0 }}>
                                    <img className="full-max-width with-margin-top" src="https://cdn.shopify.com/s/files/1/1788/1479/files/MP_3672a188-0e3c-4955-9cfb-8493fd057dc5.jpg?v=1488743602" title="MercadoPago - Medios de pago" alt="MercadoPago - Medios de pago"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="small-text center-text">
                                    La disponibilidad de los productos está sujeta a su existencia
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} className="d-sm-block d-md-none">
                            {items}
                        </Col>
                    </Row>
                    <Row hidden={items.length > 0}>
                        <Col>
                            <p>Aún no has agregado ningún artículo a tu carrito</p>
                        </Col>
                    </Row>
                    {this.props.profile.id ? <PurchaseModal canHaveInstallmentsWithoutInterest={canHaveInstallmentsWithoutInterest} total={total} show={this.state.modalShow} onHide={modalClose}/> : null}
                </Col>
            </Container>
        );
    }
}
const mapStateToProps = function(state) {
    return {
      profile: state.global.profile
    }
}
    
const mapDispatchToProps = dispatch => ({
  removeItemFromKart: (kartName, itemId) => dispatch(removeItemFromKart(kartName, itemId)),
  updateItemKartAmount: (kartName, item, amount) => dispatch(updateItemKartAmount(kartName, item, amount))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KartList);



