import React, { Component } from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Loginbar from '../../../shared/professional/Loginbar/Loginbar';
import Navigation from '../../../shared/sonoritmo/Navigation/Navigation';
import ProductList from '../../../shared/productList/ProductList';
import { searchProducts } from '../../../redux/actions';
import Footer from '../../../shared/footer/footer';
import queryString from 'query-string';
import ReactGA from 'react-ga';

class Search extends Component {

  constructor(props) {
    super(props);
    this.props = props;
  
    this.state = {
      isLoading: false,
      products: [],
      search: this.props.match.params.text ? this.props.match.params.texts : '',
      text: queryString.parse(this.props.location.search).text,
    }
    ReactGA.pageview(`${this.props.location.pathname}${this.props.location.search}`);
  }

  componentDidMount() {
    this.props.searchProducts(this.state.text);
  }

  render() {
    const BOSE_BRODUCT_MODELS_MAIN = [
      'L1COMPACT',
      'L1-COMPACT-FR',
      'L1COMPACTWIRELESSSYSTEM',
      'S1-PRO',
      'L1MODELII-B2',
      'T1-TONEMATCH-FR',
      'T4S',
      'T8S',
      'L1MODEL1S-B1',
      'F1MODEL812',
      'F1SUBWOOFER',
      'F1-TOWER',
      'F1-TOWER-DUAL',
      'F1-812-TRAVELBAG',
      'F1-SUB-TRAVELBAG',
      'S1-SLIPCOVER',
      'S1-SKINCOVER-WH',
      'S1-SKINCOVER-BK',
      'S1-SKINCOVER-BL',
      'S1PRO-BACKPACK',
      'T1POWERSUPPLY',
      'S1-BATTERYPACK',
      'PACKLITEPOWER-A1',
      'B1-BASS',
      'B2-BASS',
    ];
    
    let orderedMainProducts = [];
    BOSE_BRODUCT_MODELS_MAIN.forEach((model)=>{
      orderedMainProducts = [...orderedMainProducts, ...this.props.products.filter((product)=>product.model.startsWith(model+' -'))];
    });

    const allOtherProducts = [];
    this.props.products.forEach((product)=>{
      if(!(orderedMainProducts.some(sorted => sorted.model === product.model))) allOtherProducts.push(product);
    });

    const productsToRender = [...orderedMainProducts, ...allOtherProducts];
    
    return (
        <Container fluid>
            <Row className="sonoritmo-bg-color">
              <Loginbar name="Alberto Ayala Capetillo" customer_id="442424"/>
              <Navigation category={this.state.selectedCategory} auth={this.props.auth}/>
            </Row>
            <Row>
              <Container>
                {this.props.products.length > 0 && !this.props.isLoading && <p>Tu Búsqueda de <b>"{this.state.text}"</b> obtuvo <b>{this.props.products.length}</b> resultado(s)</p>}
              </Container>
            </Row>
            <Row className="h-100 products-content">
              <ProductList category="" products={productsToRender} page={1} pages={1} currentPage={1} nextPage={2} paginationBgColor="#008A9D" paginationFontColor="black"/>
            </Row>
            <Footer/> 
        </Container>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    isLoading: state.global.isLoading,
    products: state.commerce.products,
    pages: state.commerce.pages,
    previousPage: state.commerce.previousPage,
    nextPage: state.commerce.nextPage
  }
}
  
const mapDispatchToProps = dispatch => ({
    searchProducts: (text) => dispatch(searchProducts(text)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search);
