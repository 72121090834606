import React from 'react'
import Row from 'react-bootstrap/Row';
import { currencyFormat } from '../../utils/format'

const months = 12;
const interest = 0.1855;

const CreditPriceMonths = ({price, centered = false, hasItems = false, hasInstallments = false}) => (
    <>
        <Row hidden={price < 0.1 || hasItems || hasInstallments} style={{ height: "48px", overflow: "hidden"}}>
            <p className={centered ? "text-center full-width no-margin" : "full-width no-margin"}>
                Hasta {months} meses de ${currencyFormat(((price*(1+interest))/months))}
            </p>
        </Row>
        <Row hidden={!hasInstallments} style={{ height: "48px", overflow: "hidden"}}>
            <p className={centered ? "text-center full-width no-margin icon-success" : "full-width no-margin icon-success"}>
                12 meses sin intereses de ${currencyFormat((price/12))}
            </p>
        </Row>
    </>
)

export default CreditPriceMonths;