
import {
  IS_LOADING,
  END_LOADING,
  ADD_ITEM_TO_KART,
  REMOVE_ITEM_FROM_KART,
  UPDATE_ITEM_KART_AMOUNT,
  EMPTY_KART,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_MESSAGES_SUCCESS,
  SEND_GENERAL_MESSAGE_SUCCESS,
  NEW_CHAT_LISTENER,
  TOOGLE_CHAT,
  OPEN_CHAT,
  FETCH_USER_ORDERS_SUCCESS
} from '../actionTypes';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  kart: [],
  professionalKart: [],
  profile: {},
  messages: [],
  chatListener: null,
  chatOpen: false,
  orders: []
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case IS_LOADING: {
      return {
        ...state,
        isLoading: true
      };
    }
    case END_LOADING: {
      return {
        ...state,
        isLoading: false
      };
    }
    case ADD_ITEM_TO_KART: {
      const newState = Object.assign({}, state);
      let items = newState[action.payload.kartName];
      const item = items.find(item =>  action.payload.item.id === item.id)
      if(item && item.id) {
        if(item.amount + action.payload.item.amount <= parseInt(item.stock)){
          item.amount += action.payload.item.amount;
          items = items.filter(item =>  action.payload.item.id !== item.id)
          items = [...items, item]
          newState[action.payload.kartName] = [...items];
          toast.info("Producto agregado");
        } else {
          toast.warn("No contamos con el stock suficiente");
        }
      } else {
        if(action.payload.item.stock >= action.payload.item.amount){
          newState[action.payload.kartName] = [...items, action.payload.item]; 
          toast.info("Producto agregado");
        } else {
          toast.warn("No contamos con el stock suficiente");
        }
      }
      return newState;
    }
    case UPDATE_ITEM_KART_AMOUNT: {
      const newState = Object.assign({}, state);
      let items = newState[action.payload.kartName];
      const item = items.find(item =>  action.payload.item.id === item.id);
      if(action.payload.amount <= parseInt(item.stock)){
        item.amount = action.payload.amount;
        items = items.filter(item =>  action.payload.item.id !== item.id)
        items = [...items, item]
        newState[action.payload.kartName] = [...items];
        toast.info("Producto actualizado");
      } else {
        toast.warn("No contamos con el stock suficiente");
      }
      
      return newState;
    }
    case REMOVE_ITEM_FROM_KART: {
      let newState = Object.assign({}, state);
      let items =  [...newState[action.payload.kartName]];
      items = items.filter(item => item.id !== action.payload.itemId);
      newState[action.payload.kartName] = items;

      return newState;
    }
    case EMPTY_KART: {
      let newState = Object.assign({}, state);
      newState[action.payload.kartName] = [];

      return newState;
    }
    case FETCH_USER_PROFILE_SUCCESS: {
      const newState = Object.assign({}, state);
      newState.profile = action.payload.profile;
      
      return newState;
    }
    case FETCH_MESSAGES_SUCCESS: {
      const newState = Object.assign({}, state);
      newState.messages = action.payload.messages;
      
      return newState;
    }
    case SEND_GENERAL_MESSAGE_SUCCESS: {
      const newState = Object.assign({}, state);
      newState.messages = [...newState.messages];
      
      return newState;
    }
    case NEW_CHAT_LISTENER: {
      const newState = { ...state, chatListener: action.payload.chatListener};

      return newState;
    }
    case TOOGLE_CHAT: {
      const newState = { ...state, chatOpen: !state.chatOpen};

      return newState;
    }
    case OPEN_CHAT: {
      const newState = { ...state, chatOpen: true};

      return newState;
    }
    case FETCH_USER_ORDERS_SUCCESS: {
      const orders = action.payload.orders.map(order => {
        let mappedOrder = {...order};
        mappedOrder.descriptions = mappedOrder.descriptions.split(',');
        mappedOrder.models = mappedOrder.models.split(',');
        mappedOrder.pictures = mappedOrder.pictures.split(',');
        mappedOrder.items = [];
        mappedOrder.models.forEach((model, i) => {
          mappedOrder.items.push({
            model,
            description: mappedOrder.descriptions[i],
            picture: mappedOrder.pictures[i],
          });
        });

        return mappedOrder;
      });
      return {...state, orders: [...orders]}
    }
    default:
      return state;
  }
}
