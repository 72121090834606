import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import ProductOwlCarousel from '../../../../shared/productOwlCarousel/ProductOwlCarousel';
import Loader from '../../../../shared/loader/loader';
import { getFeaturedProducts, addItemToKart } from '../../../../redux/actions';
import { capitalize } from '../../../../utils/format';

class FeaturedProducts extends Component {
  componentDidMount() {
    this.props.getFeaturedProducts();
  }
  
  render() {
    const modelIncludes = this.props.modelIncludes;
    const modelNotIncludes = this.props.modelNotIncludes;
    let filteredProducts = this.props.featuredProducts.filter(item => item.brand === this.props.brand); //Filter By brand
    if(modelIncludes){
      filteredProducts = filteredProducts.filter(item => item.model.includes(modelIncludes))
    }
    if(modelNotIncludes){
      filteredProducts = filteredProducts.filter(item => !item.model.includes(modelNotIncludes))
    }
    return (
      <>
        <Container fluid style={{width: "100%"}}>
          <h4 className="featured-items-title"  hidden={!filteredProducts.length}>Productos destacados {capitalize(this.props.brand)} {this.props.isLoading}</h4>
          { !this.props.isLoading ? 
              (<ProductOwlCarousel {...this.props} products={filteredProducts} addToKartHandler={this.props.addItemToKart}/>):
              (<Loader/>)
          }
        </Container>
      </>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    isLoading: state.global.isLoading,
    featuredProducts: state.commerce.featuredProducts
  }
}

const mapDispatchToProps = dispatch => ({
  getFeaturedProducts: () => dispatch(getFeaturedProducts()),
  addItemToKart: (kartName, item) => dispatch(addItemToKart(kartName, item)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeaturedProducts);