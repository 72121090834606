import auth0 from 'auth0-js';
import history from '../history';
import { AUTH0_CONFIG } from '../configuration';

export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: AUTH0_CONFIG.domain,
    clientID: AUTH0_CONFIG.clientID,
    redirectUri: AUTH0_CONFIG.redirectUri,
    audience: AUTH0_CONFIG.audience,
    responseType: AUTH0_CONFIG.responseType,
    scope: AUTH0_CONFIG.scope,
    language: AUTH0_CONFIG.language,
  });

  login() {
    localStorage.setItem('redirectUrl', window.location.pathname);
    this.auth0.authorize();
  }

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        history.push(localStorage.getItem('redirectUrl') ? localStorage.getItem('redirectUrl') : "/");
      } else if (err) {
        history.push(localStorage.getItem('redirectUrl') ? localStorage.getItem('redirectUrl') : "/");
        console.error('error handling authentication', err);
      }
    });
  }

  setSession(authResult) {
    // Set the time that the Access Token will expire at
    let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    // navigate to the kart route
    history.push('/kart');
  }

  logout() {
    // Clear Access Token and ID Token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
  }

  isAuthenticated() {
    // Check whether the current time is past the 
    // Access Token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }
}