export const IS_LOADING = "IS_LOADING";
export const END_LOADING = "END_LOADING";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS"; 
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";
export const SELECT_PAGE = "SELECT_PAGE";
export const FETCH_SUBCATEGORIES = "FETCH_SUBCATEGORIES";
export const FETCH_SUBCATEGORIES_SUCCESS = "FETCH_SUBCATEGORIES_SUCCESS";
export const FETCH_SUBCATEGORIES_GROUP_SUCCESS = "FETCH_SUBCATEGORIES_GROUP_SUCCESS";
export const FETCH_SUBCATEGORIES_FAILURE = "FETCH_SUBCATEGORIES_FAILURE";
export const SELECT_SUBCATEGORY = "SELECT_SUBCATEGORY";
export const SELECT_BRAND = "SELECT_BRAND";
export const FETCH_BRANDS_SUCCESS = "FETCH_BRANDS_SUCCESS";
export const FETCH_BRANDS_FAILURE = "FETCH_BRANDS_FAILURE";
export const FETCH_TAGS_SUCCESS = "FETCH_TAGS_SUCCESS";
export const FETCH_TAGS_FAILURE = "FETCH_TAGS_FAILURE";
export const SELECT_TAG = "SELECT_TAG";
export const ADD_ITEM_TO_KART = "ADD_ITEM_TO_KART";
export const REMOVE_ITEM_FROM_KART = "REMOVE_ITEM_FROM_KART";
export const UPDATE_ITEM_KART_AMOUNT = "UPDATE_ITEM_KART_AMOUNT";
export const EMPTY_KART = "EMPTY_KART";
export const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS";
export const FETCH_USER_PROFILE_FAILURE = "FETCH_USER_PROFILE_FAILURE";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAILURE = "CREATE_ORDER_FAILURE";
export const FETCH_ITEM_SUCCESS = "FETCH_ITEM_SUCCESS";
export const FETCH_ITEM_FAILURE = "FETCH_ITEM_FAILURE";
export const CREATE_PAYMENT_PREFERENCE_SUCCESS = "FETCH_ITEM_SUCCESS";
export const CREATE_PAYMENT_PREFERENCE_FAILURE = "FETCH_ITEM_FAILURE";
export const UPDATE_PAYMENT_STATE_SUCCESS = "UPDATE_PAYMENT_STATE_SUCCESS";
export const FETCH_ADVERTISEMENTS_SUCCESS = "FETCH_ADVERTISEMENTS_SUCCESS";
export const REQUEST_ERROR = "REQUEST_ERROR";
export const FETCH_ADVERTISEMENT_SUCCESS = "FETCH_ADVERTISEMENT_SUCCESS";
export const FETCH_FEATURED_PRODUCTS_SUCCESS = "FETCH_FEATURED_PRODUCTS_SUCCESS";
export const FETCH_MESSAGES_SUCCESS = "FETCH_MESSAGES_SUCCESS";
export const SEND_GENERAL_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const NEW_CHAT_LISTENER = "NEW_CHAT_LISTENER";
export const TOOGLE_CHAT = "TOOGLE_CHAT";
export const OPEN_CHAT = "OPEN_CHAT";
export const FETCH_USER_ORDERS_SUCCESS = "FETCH_USER_ORDERS_SUCCESS";
export const FETCH_REWARDS_BALANCE_SUCCESS = "FETCH_REWARDS_BALANCE_SUCCESS";
export const EXCHANGE_REWARDS_SUCCESS = "EXCHANGE_REWARDS_SUCCESS";
export const EXCHANGE_REWARDS_FAILURE = "EXCHANGE_REWARDS_FAILURE";
export const FETCH_REWARDS_CONFIGURATION_SUCCESS = "FETCH_REWARDS_CONFIGURATION_SUCCESS";
export const FETCH_ITEM_QUESTIONS_SUCCESS = "FETCH_ITEM_QUESTIONS_SUCCESS";
export const CREATE_ITEM_QUESTION_SUCCESS = "CREATE_ITEM_QUESTION_SUCCESS";
export const NEW_ITEM_QUESTIONS_LISTENER = "NEW_ITEM_QUESTIONS_LISTENER";